import React from 'react'
import { ScreenTitle, ScreenDescription, PrimaryButton } from '../../ui/common'
import { COPY } from 'src/frontoffice/common/copy'
import { State } from './state'
import { PointerData } from 'src/types/data'
import { Maybe } from 'src/types/utils'

interface InstructionProps {
  value: State
  setValue: (v: State) => void
  onContinue: (next: Maybe<PointerData>) => void
}

const Instruction: React.FC<InstructionProps> = ({ value, onContinue }) => {
  const instruction = value.screen

  return (
    <div className="screen-instruction">
      <ScreenTitle>{instruction.title}</ScreenTitle>
      <ScreenDescription>{instruction.text}</ScreenDescription>
      <div className="buttons-group">
        <PrimaryButton onClick={() => onContinue(instruction.pointer)}>
          {COPY.CONTINUE}
        </PrimaryButton>
      </div>
    </div>
  )
}

export { Instruction }
