import { SessionReducerState } from '../../store/types';
import {
  ImageData,
  SummaryEntry,
  PointerData,
  TextInputScreenData,
  ScreenType
} from 'src/types/data';
import { Maybe } from 'src/types/utils';

export type State = {
  type: typeof ScreenType.TEXT_INPUT;
  value: string;
  screen: TextInputScreenData;
};

function getInitialState(screen: TextInputScreenData): State {
  return {
    type: ScreenType.TEXT_INPUT,
    screen,
    value: ''
  };
}

function getNextScreenPointer(state: State): Maybe<PointerData> {
  return state.screen.pointer;
}

function getSummary(
  state: State,
  sessionState: SessionReducerState
): SummaryEntry[] | null {
  return [{ title: state.screen.title || '', value: state.value }];
}

function getImages(state: State): ImageData[] {
  return state.screen.images;
}

export { getInitialState, getNextScreenPointer, getSummary, getImages };
