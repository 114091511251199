import { screens } from '../screens/';
import * as requests from '../sandbox';

import { produce } from 'immer';
import { getSessionTrackingData } from '../utils';
import {
  SessionReducerState,
  SessionReducerAction,
  ScreenState,
  Device,
  ExtraSummaryData
} from './types';

import { DispatcherWithThunk } from 'src/lib/hooks';
import { QueuedPointer } from './types';
import {
  PointerData,
  ScreenData,
  ScreenType,
  PointerType
} from 'src/types/data';
import { Maybe } from 'src/types/utils';

let initialState: SessionReducerState = {
  history: [],
  startTime: new Date().getTime(),
  state: { type: 'loading-initial-workflow' },
  extraSummaryData: {
    costs: false,
    reset: false
  },
  device: null,
  queuedPointer: undefined
};

function getScreenLoadedState(screen: ScreenData): ScreenState {
  switch (screen.type) {
    case ScreenType.BUTTONS_QUESTION:
      return screens[ScreenType.BUTTONS_QUESTION].getInitialState(screen);
    case ScreenType.DROPDOWN_QUESTION:
      return screens[ScreenType.DROPDOWN_QUESTION].getInitialState(screen);
    case ScreenType.NUMBER_INPUT:
      return screens[ScreenType.NUMBER_INPUT].getInitialState(screen);
    case ScreenType.TEXT_INPUT:
      return screens[ScreenType.TEXT_INPUT].getInitialState(screen);
    case ScreenType.INSTRUCTION:
      return screens[ScreenType.INSTRUCTION].getInitialState(screen);
    case ScreenType.CALAMITY_SELECTOR:
      return screens[ScreenType.CALAMITY_SELECTOR].getInitialState(screen);
    case ScreenType.DEVICE_SELECTOR:
      return screens[ScreenType.DEVICE_SELECTOR].getInitialState(screen);
    case ScreenType.INSTALLATION_CODE_SELECTOR:
      return screens[ScreenType.INSTALLATION_CODE_SELECTOR].getInitialState(
        screen
      );
    case ScreenType.END:
      return screens[ScreenType.END].getInitialState(screen);
    case ScreenType.MECHANIC:
      return screens[ScreenType.MECHANIC].getInitialState(screen);
    case ScreenType.SPECIAL_SELECTOR:
      return screens[ScreenType.SPECIAL_SELECTOR].getInitialState(screen);
    case ScreenType.SUB_SPECIAL_CODE_SELECTOR:
      return screens[ScreenType.SUB_SPECIAL_CODE_SELECTOR].getInitialState(
        screen
      );
  }
}

function getNextScreenPointer(screen: ScreenState): Maybe<PointerData> {
  switch (screen.type) {
    case ScreenType.BUTTONS_QUESTION:
      return screens[ScreenType.BUTTONS_QUESTION].getNextScreenPointer(screen);
    case ScreenType.DROPDOWN_QUESTION:
      return screens[ScreenType.DROPDOWN_QUESTION].getNextScreenPointer(screen);
    case ScreenType.NUMBER_INPUT:
      return screens[ScreenType.NUMBER_INPUT].getNextScreenPointer(screen);
    case ScreenType.TEXT_INPUT:
      return screens[ScreenType.TEXT_INPUT].getNextScreenPointer(screen);
    case ScreenType.INSTRUCTION:
      return screens[ScreenType.INSTRUCTION].getNextScreenPointer(screen);
    case ScreenType.CALAMITY_SELECTOR:
      return screens[ScreenType.CALAMITY_SELECTOR].getNextScreenPointer(screen);
    case ScreenType.DEVICE_SELECTOR:
      return screens[ScreenType.DEVICE_SELECTOR].getNextScreenPointer(screen);
    case ScreenType.INSTALLATION_CODE_SELECTOR:
      return screens[
        ScreenType.INSTALLATION_CODE_SELECTOR
      ].getNextScreenPointer(screen);
    case ScreenType.END:
      return screens[ScreenType.END].getNextScreenPointer(screen);
    case ScreenType.MECHANIC:
      return screens[ScreenType.MECHANIC].getNextScreenPointer(screen);
    case ScreenType.SPECIAL_SELECTOR:
      return screens[ScreenType.SPECIAL_SELECTOR].getNextScreenPointer(screen);
    case ScreenType.SUB_SPECIAL_CODE_SELECTOR:
      return screens[ScreenType.SUB_SPECIAL_CODE_SELECTOR].getNextScreenPointer(
        screen
      );
  }
}

function sessionReducer(
  state: SessionReducerState | undefined = initialState,
  action: SessionReducerAction
): SessionReducerState {
  return produce(state, (draft: any) => {
    switch (action.type) {
      case 'GO_TO_PREVIOUS_SCREEN':
        if (state.history.length < 1) return state;

        return {
          ...state,
          state: { type: 'loaded', screen: state.history.slice(-1)[0] },
          history: state.history.slice(0, -1)
        };

      case 'GO_TO_NEXT_SCREEN':
        console.log('GO_TO_NEXT_SCREEN', state);
        switch (state.state.type) {
          case 'error':
          case 'loading':
            return state;

          case 'loaded':
            if (
              state.history.length == 0 &&
              state.state.screen.type == ScreenType.BUTTONS_QUESTION
            ) {
              const nextScreenPointer = getNextScreenPointer(
                state.state.screen
              );

              if (!nextScreenPointer)
                throw new Error('nextScreenPointer is undefined');

              draft.startTime = new Date().getTime();
              draft.rootFlowId =
                nextScreenPointer.type === PointerType.WORKFLOW
                  ? nextScreenPointer.workflowId
                  : undefined;
            }

            draft.history.push(state.state.screen);
            draft.state = {
              type: 'loading',
              screen: getNextScreenPointer(state.state.screen)
            };
            break;
        }
        break;

      case 'SET_DEVICE':
        draft.device = action.payload;
        break;

      case 'UNSET_DEVICE':
        draft.device = null;
        break;

      case 'SET_QUEUED_POINTER':
        draft.queuedPointer = action.payload;
        break;

      case 'SET_LOADED_STATE':
        draft.state = { type: 'loaded', screen: action.payload };
        break;

      case 'SET_ERROR_STATE':
        draft.state = { type: 'error', error: action.payload };
        break;

      case 'SET_EXTRA_SUMMARY_DATA':
        draft.extraSummaryData = action.payload;
        break;
    }
  });
}

interface GenerateReducerOptions {
  value?: SessionReducerState;
}

type dispatcher = DispatcherWithThunk<SessionReducerAction>;

const generateReducer = ({ value = initialState }: GenerateReducerOptions) => {
  const generateActions = (dispatch: dispatcher) => ({
    goToNextScreen() {
      dispatch({
        type: 'GO_TO_NEXT_SCREEN',
        payload: null
      });
    },

    goToPreviousScreen() {
      dispatch({
        type: 'GO_TO_PREVIOUS_SCREEN',
        payload: null
      });
    },

    setDevice(device: Device) {
      dispatch({
        type: 'SET_DEVICE',
        payload: device
      });
    },

    unsetDevice() {
      dispatch({
        type: 'UNSET_DEVICE',
        payload: null
      });
    },

    setQueuedPointer(pointer: QueuedPointer) {
      dispatch({
        type: 'SET_QUEUED_POINTER',
        payload: pointer
      });
    },

    setLoadedState(screen: ScreenState) {
      dispatch({
        type: 'SET_LOADED_STATE',
        payload: screen
      });
    },

    setErrorState(error: string) {
      dispatch({
        type: 'SET_ERROR_STATE',
        payload: error
      });
    },

    setExtraSummaryData(data: ExtraSummaryData) {
      dispatch({
        type: 'SET_EXTRA_SUMMARY_DATA',
        payload: data
      });
    },

    loadScreen() {
      dispatch(loadScreen());
    },

    sendSessionTracking(state: SessionReducerState) {
      const trackingData = getSessionTrackingData(state);
      if (trackingData) {
        return requests.sendSessionTracking(trackingData);
      }
    }
  });

  function loadScreen(): any {
    // return (state: SessionReducerState, actions: GenerateActionsType) => {
    return (state: any, actions: any) => {
      state = state.currentSession;
      actions = actions.currentSession;

      if (state.state.type === 'loading-initial-workflow') {
        requests.getInitialWorkflow().then(
          workflow => {
            const initialScreenId = workflow.initialScreenId;
            const initialScreen = workflow.screens.find(
              screen => screen.id === initialScreenId
            );
            if (!initialScreen) throw new Error('Initial screen not found');
            actions.setLoadedState(getScreenLoadedState(initialScreen));
          },
          error => {
            actions.setErrorState(error);
          }
        );
      }

      if (state.state.type === 'loading') {
        console.log('loading', state);
        switch (state.state.screen.type) {
          case PointerType.WORKFLOW:
            requests.getWorkflow(state.state.screen.workflowId).then(
              workflow => {
                const initialScreenId = workflow.initialScreenId;
                const initialScreen = workflow.screens.find(
                  screen => screen.id === initialScreenId
                );
                if (!initialScreen) throw new Error('Initial screen not found');
                actions.setLoadedState(getScreenLoadedState(initialScreen));
              },
              error => {
                actions.setErrorState(error);
              }
            );
            break;

          case PointerType.SCREEN:
            const workflowId = state.state.screen.workflowId;
            const screenId = state.state.screen.screenId;
            requests.getWorkflow(workflowId).then(
              workflow => {
                const screen = workflow.screens.find(
                  screen => screen.id === screenId
                );
                if (!screen) throw new Error('Screen not found');
                actions.setLoadedState(getScreenLoadedState(screen));
              },
              error => {
                actions.setErrorState(error);
              }
            );
        }
      }
    };
  }

  return {
    generateActions,
    reducer: sessionReducer,
    initialState: value
  };
};

export { generateReducer };
