import {
  FabricantData,
  InstallationCodeData,
  InstallationData,
  MainCalamityData,
  MainSpecialData,
  ReleaseData,
  ScreenData,
  SessionTrackingData,
  SubCalamityData,
  SubSpecialCodeData,
  SubSpecialData,
  User,
  WorkflowData
} from 'src/types/data';

export const getInitialWorkflow = (): Promise<WorkflowData> => {
  return fetch('/api/call-center/workflows/initial').then(res => res.json());
};

export const getWorkflow = (id: WorkflowData['id']): Promise<WorkflowData> => {
  console.log('getWorkflow', id);
  return fetch(`/api/call-center/workflows/${id}`).then(res => res.json());
};

export const getFabricants = (): Promise<FabricantData[]> => {
  return fetch(`/api/call-center/fabricants/`).then(res => res.json());
};

export const getInstallations = (
  fabricantId: string
): Promise<InstallationData[]> => {
  return fetch(`/api/call-center/fabricants/${fabricantId}/installations`).then(
    res => res.json()
  );
};

export const getInstallationCodes = (
  installationId: string
): Promise<InstallationCodeData[]> => {
  return fetch(`/api/call-center/installations/${installationId}/codes`).then(
    res => res.json()
  );
};

export const getMainCalamities = (): Promise<MainCalamityData[]> =>
  fetch(`/api/call-center/calamities/`).then(res => res.json());

export const getSubCalamities = (
  mainCalamityId: string
): Promise<SubCalamityData[]> =>
  fetch(`/api/call-center/calamities/${mainCalamityId}/sub-calamities`).then(
    res => res.json()
  );

export const getSpecials = (): Promise<MainSpecialData[]> => {
  return fetch(`/api/call-center/specials/`).then(res => res.json());
};

export const getSubSpecials = (
  specialId: string
): Promise<SubSpecialData[]> => {
  return fetch(`/api/call-center/specials/${specialId}/sub-specials`).then(
    res => res.json()
  );
};

export const getSubSpecialCodes = (
  subSpecialId: string
): Promise<SubSpecialCodeData[]> => {
  return fetch(`/api/call-center/sub-specials/${subSpecialId}/codes`).then(
    res => res.json()
  );
};

export const sendFeedback = ({
  user,
  screen,
  feedback,
  summary
}: {
  user: string;
  screen: ScreenData;
  feedback: string;
  summary: string;
}): Promise<void> => {
  return fetch(`/api/call-center/feedbacks`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      user,
      screen,
      feedback,
      summary
    })
  }).then(() => {});
};

export const sendSessionTracking = async (data: SessionTrackingData) => {
  await fetch(`/api/call-center/tracking`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
};

let user: any = null;
export const getUser = async (): Promise<User | 'Unauthorized'> => {
  if (user) {
    return user;
  }
  const response = await fetch('/api/auth/user');

  if (!response.ok) {
    if (response.status === 401) {
      return 'Unauthorized';
    }
    return 'Unauthorized';
  }

  user = (await response.json()) as User;
  return user;
};

export const getReleases = (): Promise<ReleaseData[]> => {
  return fetch(`/api/call-center/releases/`).then(res => res.json());
};
