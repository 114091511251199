import React from 'react'
import { ScreenTitle, ScreenDescription } from '../../ui/common'
import { FinalScreenSummary } from '../../ui/FinalScreenSummary'
import { ExtraSummaryQuestions } from '../../ui/common/ExtraSummaryQuestions'
import { State } from './state'

interface EndProps {
  value: State
  setValue: (v: State) => void
  onContinue: () => void
}

const End: React.FC<EndProps> = ({ value, setValue, onContinue }) => {
  const { screen } = value

  return (
    <div className="screen-end">
      <ScreenTitle>{screen.title}</ScreenTitle>
      <ScreenDescription>{screen.text}</ScreenDescription>
      <ExtraSummaryQuestions />
      <FinalScreenSummary />
    </div>
  )
}

export { End }
