import React, { ReactNode } from 'react'

import { COPY } from './common/copy'
import { Icon } from './ui/common/index'
import { Releases } from './ui/common/Releases'

interface AppShellProps {
  children: ReactNode
}

const AppShell: React.FC<AppShellProps> = ({ children }) => {
  return (
    <div className="feenstra-call-center">
      <div className="header">
        <div className="header-left">
          <Icon
            className="logo"
            src="/images/feenstra_logo.png"
            alt="logo"
            width={132}
            height={38}
          />
        </div>
        <div className="header-right">
          <Releases />
          <h2>{COPY.TOOL_NAME}</h2>
        </div>
      </div>
      <div className="body">{children}</div>
    </div>
  )
}

export { AppShell }
