import { useEffect, RefObject } from 'react';

export const useClickOutside = (
  refs: Array<RefObject<HTMLElement>>,
  callback: (() => void) | null = null
): void => {
  useEffect(() => {
    if (!refs.length || !callback) return;
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent): void {
      // cancel for any click within any of given refs
      for (let i = 0; i < refs.length; i++) {
        if (refs[i].current && refs[i].current?.contains(event.target as Node))
          return;
      }
      // click is outside, do callback
      callback && callback();
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs, callback]);
};
