import { useContext } from 'react';
import { RootContext } from './RootContext';

const useRootState = () => {
  const rootContext = useContext(RootContext);

  if (!rootContext)
    throw new Error('useRootState must be used within a RootProvider');

  const { state, actions } = rootContext;

  return {
    state,
    actions
  };
};

export default useRootState;
