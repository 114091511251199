import { SessionReducerState } from '../../store/types';
import {
  ImageData,
  InstructionScreenData,
  PointerData,
  SummaryEntry,
  ScreenType
} from 'src/types/data';
import { Maybe } from 'src/types/utils';

export interface State {
  type: typeof ScreenType.INSTRUCTION;
  screen: InstructionScreenData;
}

function getInitialState(screen: InstructionScreenData): State {
  return {
    type: ScreenType.INSTRUCTION,
    screen
  };
}

function getNextScreenPointer(state: State): Maybe<PointerData> {
  return state.screen.pointer;
}

function getSummary(
  state: State,
  sessionState: SessionReducerState
): SummaryEntry[] | null {
  return null;
}

function getImages(state: State): ImageData[] {
  return state.screen.images;
}

export { getInitialState, getNextScreenPointer, getSummary, getImages };
