import { SessionReducerState } from '../../store/types';
import {
  EndScreenData,
  ImageData,
  PointerData,
  SummaryEntry,
  ScreenType
} from 'src/types/data';
import { Maybe } from 'src/types/utils';

export interface State {
  type: typeof ScreenType.END;
  screen: EndScreenData;
  nextPointer?: Maybe<PointerData>;
}

function getInitialState(screen: EndScreenData): State {
  return {
    type: ScreenType.END,
    screen,
    nextPointer: undefined
  };
}

const getNextScreenPointer = (state: State): Maybe<PointerData> => {
  if (!state.nextPointer) throw new Error('No next pointer');
  return state.nextPointer;
};

function getSummary(
  state: State,
  sessionState: SessionReducerState
): SummaryEntry[] | null {
  return null;
}

function getImages(state: State): ImageData[] {
  return state.screen.images;
}

export { getInitialState, getNextScreenPointer, getSummary, getImages };
