import React, { Fragment } from 'react'
import {
  ScreenTitle,
  PrimaryButton,
  Dropdown,
  ScreenDescription
} from '../../ui/common'
import { COPY } from 'src/frontoffice/common/copy'
import { AutocompleteDropdown } from '../../ui/common/autocomplete-dropdown/AutocompleteDropdown'
import compareAlphaNumericStrings from 'src/lib/misc'
import { State } from './state'

// interface UIState {
//   screen: {
//     name: string
//     text: string
//     answers: {
//       id: string
//       title: string
//       description: string
//     }[]
//     placeholder?: string
//   }
//   selectedAnswerId?: string
// }

interface DropdownQuestionProps {
  value: State
  setValue: (v: State) => void
  onContinue: () => void
}

function DropdownQuestion({
  value,
  setValue,
  onContinue
}: DropdownQuestionProps) {
  const question = value.screen
  const selectedAnswerObj = question.answers.find(
    a => a.id === value.selectedAnswerId
  )
  const answer = value.selectedAnswerId

  return (
    <div className="screen-dropdown-question">
      <ScreenTitle>{question.title}</ScreenTitle>
      <ScreenDescription>{question.text}</ScreenDescription>
      <AutocompleteDropdown
        autoFocus
        value={answer}
        placeholder={value.screen.placeholder || COPY.DROPDOWN_PLACEHOLDER}
        onChange={selectedAnswerId => setValue({ ...value, selectedAnswerId })}
        options={[...question.answers]
          .sort(compareAnswersAlphabetically)
          .map(answer => ({
            value: answer.id,
            text: answer.title,
            disabled: false,
            selected: false
          }))}
      />
      {selectedAnswerObj && (
        <Fragment>
          <ScreenDescription>{selectedAnswerObj.description}</ScreenDescription>
          <div className="buttons-group">
            <PrimaryButton onClick={() => onContinue()}>
              {COPY.CONTINUE}
            </PrimaryButton>
          </div>
        </Fragment>
      )}
    </div>
  )
}

function compareAnswersAlphabetically(
  a1: State['screen']['answers'][number],
  a2: State['screen']['answers'][number]
) {
  return compareAlphaNumericStrings(a1.title, a2.title)
}

export { DropdownQuestion }
