import { COPY } from 'src/frontoffice/common/copy';
import { SessionReducerState } from '../../store/types';
import {
  ImageData,
  SummaryEntry,
  InstallationCodeSelectorScreenData,
  PointerData,
  ScreenType
} from 'src/types/data';
import { Maybe } from 'src/types/utils';

export interface State {
  type: typeof ScreenType.INSTALLATION_CODE_SELECTOR;
  screen: InstallationCodeSelectorScreenData;
  selectedAnswer: 'yes' | 'no' | string | undefined;
  codeId: string | undefined;
  codeName: string | undefined;
  codePointer: Maybe<PointerData>;
}

function getInitialState(screen: InstallationCodeSelectorScreenData): State {
  return {
    type: ScreenType.INSTALLATION_CODE_SELECTOR,
    screen,
    selectedAnswer: undefined,
    codeId: undefined,
    codePointer: undefined,
    codeName: undefined
  };
}

const getNextScreenPointer = (state: State): Maybe<PointerData> => {
  switch (state.selectedAnswer) {
    case 'yes':
      return state.codePointer!;
    case 'no':
      return state.screen.noPointer;
    default:
      if (!state.screen.answers) {
        throw new Error(
          'No other answers found for installation code selector'
        );
      }
      const otherAnswer = state.screen.answers.find(
        a => a.id === state.selectedAnswer
      );
      if (!otherAnswer)
        throw new Error('No next screen pointer found for answer');

      return otherAnswer.pointer;
  }
};

function getSummary(
  state: State,
  sessionState: SessionReducerState
): SummaryEntry[] {
  let value = '';
  switch (state.selectedAnswer) {
    case 'yes':
      value = state.codeName!;
      break;

    case 'no':
      value = COPY.NO;
      break;

    default:
      if (!state.screen.answers)
        throw new Error(
          'No other answers found for installation code selector'
        );

      const otherAnswer = state.screen.answers.find(
        a => a.id === state.selectedAnswer
      );
      if (!otherAnswer)
        throw new Error('No next screen pointer found for answer');
      value = otherAnswer.title;
  }

  return [
    {
      title: COPY.INSTALLATION_CODE,
      value
    }
  ];
}

function getImages(state: State): ImageData[] {
  return state.screen.images;
}

export { getInitialState, getNextScreenPointer, getSummary, getImages };
