import React, { Fragment, ReactElement } from 'react'
import { COPY } from './common/copy'
import { Icon, ScreenTitle } from './ui/common'

const LoadingScreen: React.FC = (): ReactElement => {
  return (
    <Fragment>
      <Icon
        className="background-image"
        src="/svgs/background.svg"
        alt="background"
      />
      <div className="init">
        <div className="central-box">
          <ScreenTitle>{COPY.LOADING}</ScreenTitle>
        </div>
      </div>
    </Fragment>
  )
}

export { LoadingScreen }
