import { COPY } from 'src/frontoffice/common/copy';
import useSessionState from '../../store/useSessionState';

import { SessionReducerState } from '../../store/types';
import {
  SummaryEntry,
  DeviceSelectorScreenData,
  ImageData,
  ScreenType,
  PointerData
} from 'src/types/data';
import { Maybe } from 'src/types/utils';

export interface State {
  type: typeof ScreenType.DEVICE_SELECTOR;
  screen: DeviceSelectorScreenData;
  selectedInstallationImages: ImageData[];
}

function getInitialState(screen: DeviceSelectorScreenData): State {
  return {
    type: ScreenType.DEVICE_SELECTOR,
    screen,
    selectedInstallationImages: []
  };
}

const getNextScreenPointer = (state: State): Maybe<PointerData> => {
  return state.screen.pointer;
};

function getSummary(
  state: State,
  sessionState: SessionReducerState
): SummaryEntry[] | null {
  const device = sessionState.device;

  return device
    ? [
        {
          title: state.screen.summaryFabricant || COPY.FABRICANT,
          value: device.fabricantName
        },
        {
          title: state.screen.summaryInstallation || COPY.INSTALLATION,
          value: device.installationName
        }
      ]
    : null;
}

function getImages(state: State): ImageData[] {
  return [...state.screen.images, ...state.selectedInstallationImages];
}

export { getInitialState, getNextScreenPointer, getSummary, getImages };
