import { produce } from 'immer';
import { generateReducer as generateSessionReducer } from './SessionReducer';
import { DispatcherWithThunk } from 'src/lib/hooks';
import { PointerData, WorkflowData } from 'src/types/data';

type onContinueQuestionAnswerArguments = {
  answer?: string;
  next: PointerData;
};

type onContinueQuestionAnswer = (
  props: onContinueQuestionAnswerArguments
) => void;

type onContinueArguments = {
  answer?: Answer;
  next: PointerData;
};

type onContinue = (props: onContinueArguments) => void;

export type ScreenReference = {
  workflowId: string;
  screenId: string;
};

export type Answer = {
  screen: ScreenReference;
  value: string;
};

type Answers = Answer[];

type UIStateInit = {
  type: 'init';
};

type UIStateSession = {
  type: 'session';
};

type UIState = UIStateInit | UIStateSession;

export type State = {
  workflows: WorkflowData[];
  currentSession: import('./types').SessionReducerState | null;
  uiState: UIState;
};

type StartSessionAction = {
  type: 'START_SESSION';
  payload: null;
};

type EndSessionAction = {
  type: 'END_SESSION';
  payload: null;
};

type Action = StartSessionAction | EndSessionAction;

const initialState: State = {
  workflows: [],
  uiState: {
    type: 'init'
  },
  currentSession: null
};

const generateReducer = ({ value }: { value: State }) => {
  const sessionReducer = generateSessionReducer({
    value: value.currentSession || undefined
  });

  const generateActions = (
    dispatch: DispatcherWithThunk<
      Action | import('./types').SessionReducerAction
    >
  ) => {
    return {
      currentSession: sessionReducer.generateActions(dispatch),
      startSession() {
        dispatch({
          type: 'START_SESSION',
          payload: null
        });
      },
      endSession() {
        dispatch({
          type: 'END_SESSION',
          payload: null
        });
      }
    };
  };

  const reducer = (
    state: State,
    action: Action | import('./types').SessionReducerAction
  ): State => {
    return produce(state, draft => {
      switch (action.type) {
        case 'START_SESSION':
          draft.uiState.type = 'session';
          draft.currentSession = {
            startTime: new Date().getTime(),
            history: [],
            state: {
              type: 'loading-initial-workflow'
            },
            extraSummaryData: {
              costs: false,
              reset: false
            },
            device: null
          };
          break;

        case 'END_SESSION':
          draft.uiState.type = 'init';
          draft.currentSession = null;
          break;

        default:
          draft.currentSession = sessionReducer.reducer(
            state.currentSession || undefined,
            action
          );
          break;
      }
    });
  };

  return {
    generateActions,
    reducer,
    initialState: value
  };
};

export { initialState, generateReducer };
