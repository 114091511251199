import React, { Fragment, useEffect } from 'react'
import { Screen } from './Screen'
import { Summary } from './Summary'

import { BackButton, EndSessionButton, FeedbackButton } from './common'
import { FeedbackPopup } from './FeedbackPopup'
import { isFinalState } from '../utils'
import useSessionState from '../store/useSessionState'
import { ImageGallery } from './common/ImageGallery'
import { SessionReducerState, ScreenState } from '../store/types'
import { useContentVisibility } from 'src/lib/hooks'

interface SessionProps {
  goToNextScreen: () => void
  goToPreviousScreen: () => void
  setScreenStateValue: (v: ScreenState) => void
  loadScreen: () => void
  value: SessionReducerState
  onEnd: () => void
}

function Session({
  goToNextScreen,
  goToPreviousScreen,
  setScreenStateValue,
  loadScreen,
  value,
  onEnd
}: SessionProps) {
  const { state } = useSessionState()

  let content = null

  const [feedbackPopup, showFeedbackPopup, hideFeedbackPopup] =
    useContentVisibility(() => {
      return value.state.type === 'loaded' ? (
        <FeedbackPopup
          screen={value.state.screen.screen}
          onClose={() => hideFeedbackPopup()}
        />
      ) : null
    })

  useEffect(() => {
    if (
      value.state.type == 'loading' ||
      value.state.type == 'loading-initial-workflow'
    )
      loadScreen()
  }, [value.state.type, loadScreen])

  switch (value.state.type) {
    case 'loading':
    case 'loading-initial-workflow':
      content = <div className="loading">Loading...</div>
      break

    case 'error':
      content = <div className="error">Error: {value.state.error}</div>
      break

    case 'loaded':
      const screen = value.state.screen
      content = (
        <Screen
          value={screen}
          session={value}
          setValue={setScreenStateValue}
          onContinue={goToNextScreen}
          onPrevious={goToPreviousScreen}
        />
      )
      break
  }

  return (
    <Fragment>
      {feedbackPopup}
      <div className="session">
        <div className="session-header"></div>
        <div className="session-body">
          {content}
          {!isFinalState(state) && (
            <div className="session-controls">
              <ImageGallery />
              <Summary />
            </div>
          )}
        </div>
        <div className="session-footer">
          <div className="session-footer-inner">
            <div className="left">
              {value.history.length > 0 ? (
                <BackButton onClick={() => goToPreviousScreen()} />
              ) : (
                <div />
              )}
            </div>
            <div className="right">
              <FeedbackButton onClick={showFeedbackPopup} />
              <EndSessionButton onClick={() => onEnd()} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export { Session }
