import { useContext } from 'react';
import { RootContext } from './RootContext';
import { SessionReducerState } from './types';

interface SessionState {
  state: SessionReducerState;
  actions: any;
}

const useSessionState = (): SessionState => {
  const rootContext = useContext(RootContext);

  if (!rootContext) {
    throw new Error(
      'useSessionState can only be used when a session is active'
    );
  }

  const { state, actions } = rootContext;

  if (!state.currentSession) {
    throw new Error(
      'useSessionState can only be used when a session is active'
    );
  }

  return {
    state: state.currentSession,
    actions: actions.currentSession
  };
};

export default useSessionState;
