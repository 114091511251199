import React, { Fragment, useEffect, useState } from 'react'
import { Icon, SecondaryButton } from '.'
import { useContentVisibility } from 'src/lib/hooks'
import { ACL, ReleaseData } from 'src/types/data'
import { getReleases } from 'src/frontoffice/sandbox'
import { COPY } from 'src/frontoffice/common/copy'
import { parseDateString } from 'src/lib/misc'
import { useUser } from 'src/frontoffice/contexts/UserContext'

interface FullscreenReleasesProps {
  onClose: () => void
}

function Releases(): JSX.Element {
  const [fullscreenReleases, showFullscreenReleases, hideFullscreenReleases] =
    useContentVisibility(() => (
      <FullscreenReleases onClose={() => hideFullscreenReleases()} />
    ))
  const user = useUser()
  const userHasAccess = user.type === 'authenticated'

  if (!userHasAccess) return <></>

  return (
    <Fragment>
      {fullscreenReleases}
      <div className="image-gallery">
        <SecondaryButton
          style={{ fontSize: '1.8rem', padding: '4px 15px' }}
          onClick={() => showFullscreenReleases()}
        >
          {COPY.RELEASES}
        </SecondaryButton>
      </div>
    </Fragment>
  )
}

function FullscreenReleases({ onClose }: FullscreenReleasesProps): JSX.Element {
  const [releases, setReleases] = useState<ReleaseData[]>([])
  const [activeRelease, setActiveRelease] = useState<ReleaseData | null>(null)

  useEffect(() => {
    async function fetchReleases() {
      const releases = await getReleases()
      setReleases(releases)
    }
    fetchReleases()
  }, [])

  const [
    fullscreenReleaseNotes,
    showFullscreenReleaseNotes,
    hideFullscreenReleaseNotes
  ] = useContentVisibility(() => (
    <FullscreenReleaseNotes
      onClose={() => {
        hideFullscreenReleaseNotes()
        setActiveRelease(null)
      }}
      release={activeRelease}
    />
  ))

  const rowStyle = {
    borderBottom: '1px solid #ccc'
  }
  const cellStyle: React.CSSProperties = {
    padding: '10px 20px',
    maxWidth: '1000px'
  }

  return (
    <div>
      {fullscreenReleaseNotes}
      <div
        className="fullscreen-modal-background"
        onClick={() => onClose()}
        style={{
          zIndex: 1000
        }}
      >
        <div
          className="fullscreen-modal-container"
          style={{
            zIndex: 1000,
            minHeight: '300px',
            minWidth: '1000px',
            maxWidth: '1500px',
            maxHeight: '720px'
          }}
          onClick={event => event.stopPropagation()}
        >
          <Icon
            onClick={() => onClose()}
            className="close-icon"
            alt="Close"
            src="/svgs/close.svg"
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              width: '20px',
              height: '20px',
              cursor: 'pointer'
            }}
          />
          <h2 style={{ fontSize: '4rem', marginBottom: '10px' }}>Releases</h2>
          <table
            style={{
              borderCollapse: 'collapse',
              fontSize: '1.6rem'
            }}
          >
            <thead>
              <tr
                style={{
                  borderBottom: '1.5px solid #000',
                  fontWeight: 'bold',
                  fontSize: '2rem'
                }}
              >
                <th style={cellStyle}>Date</th>
                <th style={cellStyle}>Title</th>
              </tr>
            </thead>
            <tbody>
              {releases.map(release => (
                <tr key={release.id} style={rowStyle}>
                  <td style={cellStyle}>
                    {parseDateString(release.createdAt)}
                  </td>
                  <td style={cellStyle}>
                    <button
                      style={{ color: 'blue', textDecoration: 'underline' }}
                      onClick={() => {
                        setActiveRelease(release)
                        showFullscreenReleaseNotes()
                      }}
                    >
                      {release.title}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

interface FullscreenReleaseNotesProps {
  onClose: () => void
  release: ReleaseData | null
}

function FullscreenReleaseNotes({
  onClose,
  release
}: FullscreenReleaseNotesProps): JSX.Element {
  if (!release) return <></>
  return (
    <div
      className="fullscreen-modal-background"
      onClick={() => onClose()}
      style={{ zIndex: 2000 }}
    >
      <div
        className="fullscreen-modal-container"
        style={{
          zIndex: 2000,
          minHeight: '300px',
          minWidth: '480px',
          maxWidth: '1000px',
          maxHeight: '720px',
          fontSize: '1.6rem'
        }}
        onClick={event => event.stopPropagation()}
      >
        <Icon
          onClick={() => onClose()}
          className="close-icon"
          alt="Close"
          src="/svgs/close.svg"
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            width: '20px',
            height: '20px',
            cursor: 'pointer'
          }}
        />
        <h2 style={{ fontSize: '2.6rem', marginBottom: '10px' }}>
          Release Notes: {release.title}
        </h2>
        <div>
          {release.notes.split('\n').map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
        </div>
      </div>
    </div>
  )
}

export { Releases }
