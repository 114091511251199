import React from 'react'
import {
  ScreenTitle,
  ScreenDescription,
  PrimaryButton,
  Input as InputField
} from '../../ui/common'
import { COPY } from 'src/frontoffice/common/copy'
import { State } from './state'
import { Maybe } from 'src/types/utils'
import { PointerData } from 'src/types/data'

interface TextInputProps {
  value: State
  setValue: (v: State) => void
  onContinue: (next: Maybe<PointerData>) => void
}

const TextInput: React.FC<TextInputProps> = ({
  value,
  setValue,
  onContinue
}) => {
  const screen = value.screen

  return (
    <div className="screen-screen">
      <ScreenTitle>{screen.title}</ScreenTitle>
      <ScreenDescription>{screen.text}</ScreenDescription>
      <InputField
        type="text"
        placeholder={screen.placeholder}
        value={value.value}
        onChange={(e: any) => setValue({ ...value, value: e.target.value })}
      />
      <div className="buttons-group">
        <PrimaryButton onClick={() => onContinue(screen.pointer)}>
          {COPY.CONTINUE}
        </PrimaryButton>
      </div>
    </div>
  )
}

export { TextInput }
