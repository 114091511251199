import React, { Fragment, useEffect, useState } from 'react'

import useSessionState from '../store/useSessionState'
import { PrimaryButton } from './common'
import { COPY } from 'src/frontoffice/common/copy'

interface EditableSummaryProps {
  title: string
  summary: string
  showCopyButton?: boolean
}

const EditableSummary: React.FC<EditableSummaryProps> = ({
  title,
  summary,
  showCopyButton = true
}) => {
  const [value, setValue] = useState(summary)
  const [copyText, setCopyText] = useState(COPY.COPY)
  const [hasCopied, setHasCopied] = useState(false)
  const { state, actions } = useSessionState()

  useEffect(() => {
    setValue(summary)
  }, [summary])

  function onCopyClick() {
    setHasCopied(true)
    navigator.clipboard.writeText(value).then(() => {
      setCopyText(COPY.COPIED)
      setTimeout(() => {
        setCopyText(COPY.COPY)
        if (!hasCopied) {
          actions.sendSessionTracking(state)
        }
      }, 1000)
    })
  }

  return (
    <div className="editable-summary-container">
      <div className="summary editable-summary">
        <h3 className="no-reset title">{title}</h3>
        <textarea
          className="no-reset"
          value={value}
          onChange={e => setValue(e.target.value)}
        />
      </div>
      {showCopyButton && (
        <div className="buttons-group">
          <PrimaryButton onClick={onCopyClick}>{copyText}</PrimaryButton>
        </div>
      )}
    </div>
  )
}

export { EditableSummary }
