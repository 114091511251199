import { COPY } from 'src/frontoffice/common/copy';
import useSessionState from '../../store/useSessionState';

import {
  ImageData,
  PointerData,
  SummaryEntry,
  SpecialSelectorScreenData,
  ScreenType
} from 'src/types/data';
import { SessionReducerState } from '../../store/types';
import { Maybe } from 'src/types/utils';

export interface State {
  type: typeof ScreenType.SPECIAL_SELECTOR;
  screen: SpecialSelectorScreenData;
  selectedImages: ImageData[];
  mainSpecialId?: string;
  mainSpecialName?: string;
  subSpecialId?: string;
  subSpecialName?: string;
}

function getInitialState(screen: SpecialSelectorScreenData): State {
  return {
    type: ScreenType.SPECIAL_SELECTOR,
    screen,
    selectedImages: []
  };
}

const getNextScreenPointer = (state: State): Maybe<PointerData> => {
  return state.screen.pointer;
};

function getSummary(
  state: State,
  sessionState: SessionReducerState
): SummaryEntry[] | null {
  return [
    {
      title: state.screen.summaryMainSpecial || COPY.FABRICANT,
      value: state.mainSpecialName || ''
    },
    {
      title: state.screen.summarySubSpecial || COPY.INSTALLATION,
      value: state.subSpecialName || ''
    }
  ];
}

function getImages(state: State): ImageData[] {
  return [...state.screen.images, ...state.selectedImages];
}

export { getInitialState, getNextScreenPointer, getSummary, getImages };
