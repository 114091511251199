import React from 'react'
import { RootContextProvider } from './store/RootContext'
import { CallCenter } from './ui/CallCenter'
import { State } from './store/RootReducer'

const initialReducerState: State = {
  workflows: [],
  uiState: {
    type: 'init'
  },
  currentSession: null
}

interface ComponentProps {
  runTrigger: (triggerName: string) => void
}

export const Component = ({ ...props }: ComponentProps) => {
  return (
    <RootContextProvider
      value={initialReducerState}
      // @TODO change this when integrating with SSO
      triggers={{ triggerLogout: () => props.runTrigger('whenClickLogout') }}
    >
      <CallCenter />
    </RootContextProvider>
  )
}
