import { breakUrlString } from 'src/frontoffice/utils'
import React, { ReactNode } from 'react'
import { COPY } from 'src/frontoffice/common/copy'

interface IconProps {
  src: string
  alt: string
  className?: string
  [key: string]: any
}

// export function Icon({ src, alt, className = '', ...props }: IconProps) {
//   return (
//     <div className={`icon ${className}`}>
//       <img alt="alt" src={src} {...props} />
//     </div>
//   )
// }

export function Icon({ alt, className = '', ...props }: IconProps) {
  return (
    <span className={`icon ${className}`} {...props}>
      <img alt={alt} {...props} />
    </span>
  )
}

interface ScreenTitleProps {
  children: ReactNode
}

export function ScreenTitle({ children }: ScreenTitleProps) {
  return <h3 className="no-reset screen-title">{children}</h3>
}

interface ScreenSubtitleProps {
  children: ReactNode
}

export function ScreenSubtitle({ children }: ScreenSubtitleProps) {
  return <h4 className="no-reset screen-subtitle">{children}</h4>
}

interface ScreenDescriptionProps {
  children: ReactNode
}

export function ScreenDescription({ children }: ScreenDescriptionProps) {
  if (typeof children === 'string' && children.includes('http')) {
    const descriptionChunks = breakUrlString(children)
    return (
      <p className="no-reset screen-description">
        {descriptionChunks.map((chunk, index) => {
          const key = `description-${index}-${chunk.value}`
          if (chunk.type === 'url') {
            return (
              <a href={chunk.value} target="_blank" key={key} rel="noreferrer">
                {chunk.value}
              </a>
            )
          } else {
            return <span key={key}>{chunk.value}</span>
          }
        })}
      </p>
    )
  } else {
    return <p className="no-reset screen-description">{children}</p>
  }
}

interface ScreenErrorMessageProps {
  children: ReactNode
}

export function ScreenErrorMessage({ children }: ScreenErrorMessageProps) {
  return <p className="no-reset screen-error-message">{children}</p>
}

interface ButtonProps {
  children?: ReactNode
  className?: string
  [key: string]: any
}

export function PrimaryButton(props: ButtonProps) {
  return (
    <button
      {...props}
      className={'no-reset primary-button ' + (props.className || '')}
    >
      {props.children}
    </button>
  )
}

export function SecondaryButton(props: ButtonProps) {
  return (
    <button
      {...props}
      className={'no-reset secondary-button ' + (props.className || '')}
    >
      {props.children}
    </button>
  )
}

export function TertiaryButton(props: ButtonProps) {
  return (
    <button
      {...props}
      className={'no-reset tertiary-button ' + (props.className || '')}
    >
      {props.children}
    </button>
  )
}

interface DropdownProps {
  children: ReactNode
  [key: string]: any
}

export function Dropdown(props: DropdownProps) {
  return (
    <select className="no-reset dropdown" {...props}>
      {props.children}
    </select>
  )
}

interface InputProps {
  className?: string
  [key: string]: any
}

export function Input(props: InputProps) {
  return (
    <input
      {...props}
      className={'no-reset input ' + (props.className || '')}
    ></input>
  )
}

export function BackButton(props: ButtonProps) {
  return (
    <TertiaryButton {...props}>
      <Icon className="icon" src="/svgs/back.svg" alt="Back" />
      <span className="text">{COPY.BACK}</span>
    </TertiaryButton>
  )
}

export function FeedbackButton(props: ButtonProps) {
  return (
    <TertiaryButton {...props}>
      <Icon className="icon" src="/svgs/feedback.svg" alt="Feedback" />
      <span className="text">{COPY.FEEDBACK}</span>
    </TertiaryButton>
  )
}

interface GalleryButtonProps {
  className?: string
  numberOfImages?: number
  [key: string]: any
}

export function GalleryButton({
  className = '',
  numberOfImages = 0,
  ...props
}: GalleryButtonProps) {
  return (
    <TertiaryButton
      {...props}
      className={className + ' gallery-button'}
      disabled={numberOfImages == 0}
    >
      <Icon className="icon gallery" src="/svgs/gallery.svg" alt="Gallery" />
      <span className="text">{COPY.SHOW_IMAGES}</span>
      {numberOfImages > 0 && (
        <div className="notification">
          <span>{numberOfImages}</span>
        </div>
      )}
    </TertiaryButton>
  )
}

export function EndSessionButton(props: ButtonProps) {
  return (
    <TertiaryButton {...props}>
      <Icon className="icon" src="/svgs/endsession.svg" alt="End Session" />
      <span className="text">{COPY.END_SESSION}</span>
    </TertiaryButton>
  )
}
