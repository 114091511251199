import { SessionReducerState } from '../../store/types';
import {
  SummaryEntry,
  CalamitySelectorScreenData,
  ImageData,
  ScreenType,
  PointerData
} from 'src/types/data';
import { Maybe } from 'src/types/utils';

export type State = {
  type: typeof ScreenType.CALAMITY_SELECTOR;
  screen: CalamitySelectorScreenData;
  selectedInstallationImages: ImageData[];
  mainCalamityId?: string;
  mainCalamityName?: string;
  subCalamityId?: string;
  subCalamityName?: string;
  nextPointer?: Maybe<PointerData>;
};

function getInitialState(screen: CalamitySelectorScreenData): State {
  return {
    type: ScreenType.CALAMITY_SELECTOR,
    screen,
    selectedInstallationImages: [],
    mainCalamityId: undefined,
    mainCalamityName: undefined,
    subCalamityId: undefined,
    subCalamityName: undefined,
    nextPointer: undefined
  };
}

// @TODO - We need to fix this
const getNextScreenPointer = (state: State): Maybe<PointerData> => {
  return state.nextPointer;
};

function getSummary(
  state: State,
  sessionState: SessionReducerState
): SummaryEntry[] | null {
  return [
    {
      title: state.screen.mainCalamity || '',
      value: state.mainCalamityName || ''
    },
    {
      title: state.screen.subCalamity || '',
      value: state.subCalamityName || ''
    }
  ];
}

function getImages(state: State): ImageData[] {
  return [...state.screen.images, ...state.selectedInstallationImages];
}

export { getInitialState, getNextScreenPointer, getSummary, getImages };
