import React from 'react'

import { getScreenSummary } from '../utils'
import useSessionState from '../store/useSessionState'
import { COPY } from 'src/frontoffice/common/copy'

interface SummaryEntry {
  title: string
  value: string
}

const Summary: React.FC = () => {
  const { state: sessionState } = useSessionState()

  const summary: SummaryEntry[] = sessionState.history
    .map(s => getScreenSummary(s, sessionState))
    .filter((v): v is SummaryEntry[] => v !== null)
    .flat()

  return (
    <div className="summary">
      <h3 className="no-reset title">{COPY.SUMMARY}</h3>
      {summary.map(({ title, value }) => {
        return (
          <p key={title} className="no-reset summary-entry">
            <span className="title">{title}:</span>
            <span className="value"> {value}</span>
          </p>
        )
      })}
    </div>
  )
}

export { Summary }
