import React, { createContext, ReactNode } from 'react'
import { generateReducer, State } from './RootReducer'
import { useReducerWithThunk } from 'src/lib/hooks'

export interface RootContextType {
  state: State
  actions: ReturnType<ReturnType<typeof generateReducer>['generateActions']>
  triggers: {
    triggerLogout: () => void
  }
}

const RootContext = createContext<RootContextType | undefined>(undefined)

interface RootContextProviderProps {
  children: ReactNode
  value: State
  triggers: {
    triggerLogout: () => void
  }
}

function RootContextProvider({
  children,
  value,
  triggers
}: RootContextProviderProps): JSX.Element {
  const [state, actions] = useReducerWithThunk(generateReducer({ value }))

  return (
    <RootContext.Provider value={{ state, actions, triggers }}>
      {children}
    </RootContext.Provider>
  )
}

export { RootContextProvider, RootContext }
