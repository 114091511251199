import * as buttonsQuestion from './buttonsQuestion/';
import * as deviceSelector from './deviceSelector/';
import * as dropdownQuestion from './dropdownQuestion/';
import * as textInput from './text-input/';
import * as numberInput from './number-input/';
import * as end from './end/';
import * as installationCodeSelector from './installationCodeSelector/';
import * as instruction from './instruction/';
import * as mechanic from './mechanic/';
import * as calamitySelector from './calamitySelector/';
import * as specialSelector from './specialSelector/';
import * as subSpecialCodeSelector from './subSpecialCodeSelector/';
import { ScreenType } from '../../types/data';

export const screens = {
  [ScreenType.BUTTONS_QUESTION]: buttonsQuestion,
  [ScreenType.DROPDOWN_QUESTION]: dropdownQuestion,
  [ScreenType.DEVICE_SELECTOR]: deviceSelector,
  [ScreenType.CALAMITY_SELECTOR]: calamitySelector,
  [ScreenType.TEXT_INPUT]: textInput,
  [ScreenType.NUMBER_INPUT]: numberInput,
  [ScreenType.END]: end,
  [ScreenType.INSTALLATION_CODE_SELECTOR]: installationCodeSelector,
  [ScreenType.INSTRUCTION]: instruction,
  [ScreenType.MECHANIC]: mechanic,
  [ScreenType.SPECIAL_SELECTOR]: specialSelector,
  [ScreenType.SUB_SPECIAL_CODE_SELECTOR]: subSpecialCodeSelector
};

export type ScreenHandlerType = (typeof screens)[keyof typeof screens];

export type ScreenComponentType = ScreenHandlerType['Component'];

export type ScreenGetInitialStateType = ScreenHandlerType['getInitialState'];

export type ScreenComponentIntstanceType = ReturnType<ScreenComponentType>;
