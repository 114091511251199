import React, { useEffect, useState } from 'react'
import { getMainCalamities, getSubCalamities } from '../../sandbox'
import { ScreenTitle, PrimaryButton, ScreenDescription } from '../../ui/common'
import { AutocompleteDropdown } from '../../ui/common/autocomplete-dropdown/AutocompleteDropdown'
import { COPY } from 'src/frontoffice/common/copy'
import { State } from './state'
import { MainCalamityData, SubCalamityData } from 'src/types/data'
import { AsyncDataState } from 'src/types//utils'

let initialMainCalamitiesState: AsyncDataState<MainCalamityData[]> = {
  id: 'loading'
}

let initialSubCalamitiesState: AsyncDataState<SubCalamityData[]> = {
  id: 'loading'
}

interface CalamitySelectorProps {
  value: State
  setValue: (v: State) => void
  onContinue: () => void
}

function CalamitySelector({
  value,
  setValue,
  onContinue
}: CalamitySelectorProps) {
  const {
    mainCalamityId = undefined,
    mainCalamityName = undefined,
    subCalamityId = undefined
  } = value

  const setMainCalamityId = (mainCalamityId?: string) => {
    if (mainCalamitiesState.id !== 'ready') return
    const mainCalamity = mainCalamitiesState.data.find(
      f => f.id === mainCalamityId
    )

    setValue({
      ...value,
      mainCalamityId: mainCalamity ? mainCalamity.id : undefined,
      mainCalamityName: mainCalamity ? mainCalamity.name : undefined,
      subCalamityId: undefined,
      subCalamityName: undefined,
      nextPointer: undefined
    })
  }

  const setSubCalamityId = (subCalamityId?: string) => {
    if (subCalamitiesState.id !== 'ready') return
    const subCalamity = subCalamitiesState.data.find(
      i => i.id === subCalamityId
    )
    setValue({
      ...value,
      selectedInstallationImages: subCalamity ? subCalamity.images : [],
      mainCalamityId,
      mainCalamityName,
      subCalamityId: subCalamity ? subCalamity.id : undefined,
      subCalamityName: subCalamity ? subCalamity.name : undefined,
      nextPointer: subCalamity ? subCalamity.pointer : undefined
    })
  }

  const [mainCalamitiesState, setMainCalamitiesState] = useState(
    initialMainCalamitiesState
  )
  const [subCalamitiesState, setSubCalamitiesState] = useState(
    initialSubCalamitiesState
  )

  useEffect(() => {
    if (mainCalamitiesState.id === 'loading') {
      getMainCalamities().then(
        mainCalamities => {
          setMainCalamitiesState({ id: 'ready', data: mainCalamities })
        },
        error => {
          setMainCalamitiesState({ id: 'error', error })
        }
      )
    }
  }, [mainCalamitiesState.id])

  useEffect(() => {
    if (mainCalamityId) {
      setSubCalamitiesState(initialSubCalamitiesState)
      getSubCalamities(mainCalamityId).then(
        subCalamitys => {
          setSubCalamitiesState({ id: 'ready', data: subCalamitys })
        },
        error => {
          setSubCalamitiesState({ id: 'error', error })
        }
      )
    }
  }, [mainCalamityId])

  const finalMainCalamityId =
    mainCalamitiesState.id === 'ready' &&
    mainCalamitiesState.data.find(f => f.id === mainCalamityId)
      ? mainCalamityId
      : ''

  return (
    <div className="device-selector">
      <ScreenTitle>{value.screen.title}</ScreenTitle>
      <ScreenDescription> </ScreenDescription>
      <div className="device-selector__select">
        <AutocompleteDropdown
          autoFocus
          placeholder={value.screen.mainDropdownPlaceholder || ''}
          value={finalMainCalamityId}
          onChange={setMainCalamityId}
          options={
            mainCalamitiesState.id === 'ready'
              ? mainCalamitiesState.data.map(f => ({
                  text: f.name,
                  value: f.id,
                  disabled: false,
                  selected: false
                }))
              : []
          }
        />
      </div>
      <div className="device-selector__select">
        <AutocompleteDropdown
          placeholder={value.screen.subDropdownPlaceholder || ''}
          value={
            finalMainCalamityId &&
            subCalamitiesState.id === 'ready' &&
            subCalamitiesState.data.find(f => f.id === subCalamityId)
              ? subCalamityId
              : ''
          }
          onChange={setSubCalamityId}
          options={
            finalMainCalamityId && subCalamitiesState.id === 'ready'
              ? subCalamitiesState.data.map(f => ({
                  text: f.name,
                  value: f.id,
                  disabled: false,
                  selected: false
                }))
              : []
          }
        />
      </div>
      {mainCalamityId && subCalamityId && (
        <div className="buttons-group">
          <PrimaryButton onClick={() => onContinue()}>
            {COPY.CONTINUE}
          </PrimaryButton>
        </div>
      )}
    </div>
  )
}

export { CalamitySelector }
