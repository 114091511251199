export enum ScreenType {
  BUTTONS_QUESTION = 'BUTTONS_QUESTION',
  DROPDOWN_QUESTION = 'DROPDOWN_QUESTION',
  CALAMITY_SELECTOR = 'CALAMITY_SELECTOR',
  DEVICE_SELECTOR = 'DEVICE_SELECTOR',
  END = 'END',
  MECHANIC = 'MECHANIC',
  INSTALLATION_CODE_SELECTOR = 'INSTALLATION_CODE_SELECTOR',
  INSTRUCTION = 'INSTRUCTION',
  NUMBER_INPUT = 'NUMBER_INPUT',
  TEXT_INPUT = 'TEXT_INPUT',
  SPECIAL_SELECTOR = 'SPECIAL_SELECTOR',
  SUB_SPECIAL_CODE_SELECTOR = 'SUB_SPECIAL_CODE_SELECTOR'
}

export enum PointerType {
  SCREEN = 'SCREEN',
  WORKFLOW = 'WORKFLOW'
}

export interface ModelData {}

export interface ConfigurationData extends ModelData {
  id: string;
  initialWorkflowId: string | null;
}

//
// Pointers
//
export interface IPointerData extends ModelData {
  id: string;
  type: PointerType;
}

export interface WorkflowPointerData extends IPointerData {
  type: typeof PointerType.WORKFLOW;
  id: string;
  workflowId: string;
}

export interface ScreenPointerData extends IPointerData {
  type: typeof PointerType.SCREEN;
  id: string;
  workflowId: string;
  screenId: string;
}

export type PointerData = WorkflowPointerData | ScreenPointerData;

//
// Image
//
export interface ImageData extends ModelData {
  id: string;
  url: string;
  name: string;
}

//
// Workflow
//
export interface WorkflowData extends ModelData {
  id: string;
  name: string;
  initialScreenId: string | null;
  screens: ScreenData[];
}

//
// Screens
//
export interface IScreenData extends Omit<ModelData, 'name'> {
  id: string;
  type: ScreenType;
  title: string | null;
  text: string | null;
  workflowId: string;
  images: ImageData[];
}

export interface ButtonsQuestionScreenData extends IScreenData {
  type: typeof ScreenType.BUTTONS_QUESTION;
  answers: ButtonsQuestionScreenAnswerData[];
}

export interface ButtonsQuestionScreenAnswerData extends ModelData {
  id: string;
  index: number;
  title: string;
  description: string | null;
  pointer?: PointerData;
}

export interface DropdownQuestionScreenData extends IScreenData {
  type: typeof ScreenType.DROPDOWN_QUESTION;
  placeholder: string | null;
  answers: DropdownQuestionScreenAnswerData[];
}

export interface DropdownQuestionScreenAnswerData extends ModelData {
  id: string;
  index: number;
  title: string;
  description: string | null;
  dropdownQuestionScreenId: string;
  pointer?: PointerData;
}

export interface CalamitySelectorScreenData extends IScreenData {
  type: typeof ScreenType.CALAMITY_SELECTOR;
  mainDropdownPlaceholder: string | null;
  subDropdownPlaceholder: string | null;
  mainCalamity: string | null;
  subCalamity: string | null;
}

export interface DeviceSelectorScreenData extends IScreenData {
  type: typeof ScreenType.DEVICE_SELECTOR;
  fabricantDropdownPlaceholder: string | null;
  installationDropdownPlaceholder: string | null;
  summaryFabricant: string | null;
  summaryInstallation: string | null;
  pointer?: PointerData;
}

export interface EndScreenData extends IScreenData {
  type: typeof ScreenType.END;
  summary: string | null;
}

export interface MechanicScreenData extends IScreenData {
  type: typeof ScreenType.MECHANIC;
  summary: string | null;
}

export interface InstallationCodeData extends ModelData {
  id: string;
  installationId: string;
  name: string;
  description: string | null;
  pointer?: PointerData;
}

export interface InstallationCodeSelectorScreenData extends IScreenData {
  type: typeof ScreenType.INSTALLATION_CODE_SELECTOR;
  yesText: string | null;
  noText: string | null;
  askForCode: boolean;
  noPointer?: PointerData;
  yesPointer?: PointerData;
  pointer?: PointerData;
  answers: InstallationCodeSelectorScreenAnswerData[];
}

export interface InstallationCodeSelectorScreenAnswerData extends ModelData {
  id: string;
  index: number;
  title: string;
  description: string | null;
  pointer?: PointerData;
}

export interface InstructionScreenData extends IScreenData {
  type: typeof ScreenType.INSTRUCTION;
  pointer?: PointerData;
}

export interface NumberInputScreenData extends IScreenData {
  type: typeof ScreenType.NUMBER_INPUT;
  placeholder: string | null;
  min?: number;
  max?: number;
  pointer?: PointerData;
}

export interface TextInputScreenData extends IScreenData {
  type: typeof ScreenType.TEXT_INPUT;
  placeholder: string | null;
  pointer?: PointerData;
}

export interface SpecialSelectorScreenData extends IScreenData {
  type: typeof ScreenType.SPECIAL_SELECTOR;
  mainDropdownPlaceholder: string | null;
  subDropdownPlaceholder: string | null;
  summaryMainSpecial: string | null;
  summarySubSpecial: string | null;
  pointer?: PointerData;
}

export interface SubSpecialCodeSelectorScreenData extends IScreenData {
  type: typeof ScreenType.SUB_SPECIAL_CODE_SELECTOR;
}

export type ScreenData =
  | ButtonsQuestionScreenData
  | DropdownQuestionScreenData
  | CalamitySelectorScreenData
  | DeviceSelectorScreenData
  | EndScreenData
  | MechanicScreenData
  | InstallationCodeSelectorScreenData
  | InstructionScreenData
  | NumberInputScreenData
  | TextInputScreenData
  | SpecialSelectorScreenData
  | SubSpecialCodeSelectorScreenData;

//
// Installations
//
export interface FabricantData extends ModelData {
  id: string;
  name: string;
}

export interface InstallationData extends ModelData {
  id: string;
  fabricantId: string;
  name: string;
  description: string | null;
  images: ImageData[];
}

export interface InstallationWithFabricantData extends InstallationData {
  fabricant: FabricantData;
}

export interface InstallationCodeData {
  id: string;
  installationId: string;
  name: string;
  description: string | null;
  pointer?: PointerData;
}

//
// Specials
//
export interface MainSpecialData extends ModelData {
  id: string;
  name: string;
}

export interface SubSpecialData extends ModelData {
  id: string;
  name: string;
  description: string | null;
  mainSpecialId: string;
  images: ImageData[];
}

export interface SubSpecialCodeData extends ModelData {
  id: string;
  name: string;
  description: string | null;
  subSpecialId: string;
  pointer?: PointerData;
}

//
// Calamities
//
export interface MainCalamityData extends ModelData {
  id: string;
  name: string;
}

export interface SubCalamityData extends ModelData {
  id: string;
  name: string;
  description: string | null;
  mainCalamityId: string;
  pointer?: PointerData;
  images: ImageData[];
}

export type Configuration = {
  id: string;
  initialWorkflowId: string | null;
};

export type SummaryEntry = {
  title: string;
  value: string;
};

export enum SessionTrackingResultEnum {
  SOLVED = 'SOLVED',
  MECHANIC = 'MECHANIC'
}

export type SessionTrackingData = {
  workflowId: string;
  fabricantId?: string;
  installationId?: string;
  fabricantName?: string;
  installationName?: string;
  duration: number; // in milliseconds
  result: SessionTrackingResultEnum;
};

export type FeedbackData = {
  screen: ScreenData;
  feedback: string;
  summary: string;
};

export enum ACL {
  CALL_CENTER = 'CALL_CENTER',
  BACKOFFICE = 'BACKOFFICE'
}

export type User = {
  email: string;
  acls: ACL[];
};

export type ReleaseData = {
  id: string;
  title: string;
  notes: string;
  createdAt: string;
};
