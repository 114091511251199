import React, { useEffect, useState } from 'react'
import { getSubSpecialCodes } from '../../sandbox'
import {
  ScreenTitle,
  Dropdown,
  PrimaryButton,
  SecondaryButton,
  ScreenDescription
} from '../../ui/common'
import { AutocompleteDropdown } from '../../ui/common/autocomplete-dropdown/AutocompleteDropdown'
import useSessionState from '../../store/useSessionState'
import { COPY } from 'src/frontoffice/common/copy'
import { State } from './state'
import { AsyncDataState } from 'src/types/utils'
import { SubSpecialCodeData, ScreenType } from 'src/types/data'

let initialSubSpecialCodesState: AsyncDataState<SubSpecialCodeData[]> = {
  id: 'loading'
}

interface SubSpecialCodeSelectorProps {
  value: State
  setValue: (v: State) => void
  onContinue: () => void
}

function SubSpecialCodeSelector({
  value,
  setValue,
  onContinue
}: SubSpecialCodeSelectorProps) {
  const { state: sessionData } = useSessionState()
  const specialSelectorScreen = sessionData.history.find(
    s => s.type === ScreenType.SPECIAL_SELECTOR
  )

  if (!specialSelectorScreen)
    throw new Error('SpecialSelectorScreenData not found')

  if (specialSelectorScreen.type !== ScreenType.SPECIAL_SELECTOR)
    throw new Error('SpecialSelectorScreenData is not of type SPECIAL_SELECTOR')

  const subSpecialId = specialSelectorScreen.subSpecialId

  console.log(specialSelectorScreen)
  console.log('subSpecialId', subSpecialId)

  if (!subSpecialId) throw new Error('subSpecialId not found')

  const { codeId } = value
  const [codesState, setCodesState] = useState(initialSubSpecialCodesState)

  const setCodeId = (codeId?: string) => {
    if (!codeId) return
    if (codesState.id !== 'ready') return

    const code = codesState.data.find(f => f.id === codeId)

    if (!code) return

    setValue({
      ...value,
      codeId,
      codePointer: code.pointer,
      codeName: codeId === 'no' ? 'No Code' : code.name
    })
  }

  useEffect(() => {
    if (codesState.id === 'loading') {
      getSubSpecialCodes(subSpecialId).then(
        codes => {
          setCodesState({ id: 'ready', data: codes })
        },
        error => {
          setCodesState({ id: 'error', error })
        }
      )
    }
  }, [codesState.id, subSpecialId])

  return (
    <div className="device-selector">
      <div className="device-selector__select">
        <ScreenTitle>{value.screen.title}</ScreenTitle>
        <ScreenDescription> </ScreenDescription>
        <AutocompleteDropdown
          autoFocus
          onChange={setCodeId}
          value={codeId}
          placeholder={value.screen.title || COPY.SEARCH_FOR_CODE}
          options={
            codesState.id === 'ready'
              ? codesState.data.map(c => ({
                  value: c.id,
                  text: c.name,
                  disabled: false,
                  selected: false
                }))
              : []
          }
        />
      </div>
      {codeId && (
        <div className="buttons-group">
          <PrimaryButton
            className="no-reset primary"
            onClick={() => onContinue()}
          >
            {COPY.CONTINUE}
          </PrimaryButton>
        </div>
      )}
    </div>
  )
}

export { SubSpecialCodeSelector }
