/**
 * Compares two strings, sorting numbers before letters.
 * @param a - The first string to compare.
 * @param b - The second string to compare.
 * @returns A negative number if `a` should be sorted before `b`, a positive
 * number if `a` should be sorted after `b`, or `0` if `a` and `b` are equal.
 */
export default function compareAlphaNumericStrings(a: string, b: string) {
  const isANumber = /^\d/.test(a);
  const isBNumber = /^\d/.test(b);

  if (isANumber && isBNumber) {
    return parseInt(a) - parseInt(b);
  }

  if (isANumber) {
    return -1;
  }

  if (isBNumber) {
    return 1;
  }

  return a.localeCompare(b);
}

interface BoundingClientRect {
  left: number;
  top: number;
  right: number;
  bottom: number;
  x: number;
  y: number;
  width: number;
  height: number;
}

export const getBoundingClientRect = (el: HTMLElement): BoundingClientRect => {
  let brct = el.getBoundingClientRect() as BoundingClientRect;
  if (isNaN(brct.x) || isNaN(brct.y)) {
    let x = 0;
    let y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      x += el.offsetLeft - el.scrollLeft;
      y += el.offsetTop - el.scrollTop;
      el = el.offsetParent as HTMLElement;
    }
    brct.x = x;
    brct.y = y;
  }
  return brct;
};

export function parseDateString(dateString: string): string {
  const date = new Date(dateString);
  if (!(date instanceof Date) || isNaN(date.valueOf())) {
    return 'N/A';
  }
  return Intl.DateTimeFormat('en-gb', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  }).format(date);
}
