import React from 'react'
import { Checkbox } from './Checkbox'
import { COPY } from 'src/frontoffice/common/copy'
import useSessionState from '../../store/useSessionState'

interface ExtraSummaryData {
  costs: boolean
  reset: boolean
}

function ExtraSummaryQuestions(): JSX.Element {
  const { state, actions } = useSessionState()

  return (
    <div className="extra-summary-questions">
      <Checkbox
        label={COPY.COSTS_DISCUSSED}
        checked={state.extraSummaryData.costs}
        onChange={(costs: boolean) => {
          actions.setExtraSummaryData({
            ...state.extraSummaryData,
            costs
          })
        }}
      />
      <Checkbox
        label={COPY.RESET}
        checked={state.extraSummaryData.reset}
        onChange={(reset: boolean) => {
          actions.setExtraSummaryData({
            ...state.extraSummaryData,
            reset
          })
        }}
      />
    </div>
  )
}

export { ExtraSummaryQuestions }
