import React from 'react'

interface DropdownOptionType {
  value: string
  text: string
  selected?: boolean
  focused?: boolean
}

interface DropdownOptionProps {
  option: DropdownOptionType
  onChange: (value: string) => void
}

export const DropdownOption: React.FC<DropdownOptionProps> = ({
  option,
  onChange
}) => {
  return (
    <button
      className={
        'button-dropdown-option' +
        (option.selected ? ' selected' : '') +
        (option.focused ? ' focused' : '')
      }
      onClick={() => {
        onChange(option.value)
      }}
      onMouseDown={e => e.preventDefault()}
      type="button"
      title={option.text}
    >
      <span className="text">{option.text}</span>
    </button>
  )
}
