import React from 'react'
import { Session } from './Session'
import useRootState from '../store/useRootState'
import { AppShell } from 'src/frontoffice/AppShell'
import { InitialScreen } from 'src/frontoffice/InitialScreen'
import { useUser } from '../contexts/UserContext'
import { ACL } from '../../types/data'
import { LoadingScreen } from '../LoadingScreen'
import { UnauthorizedScreen } from '../UnauthorizedScreen'
import { LoginScreen } from '../LoginScreen'

const CallCenter: React.FC = () => {
  const { state, actions } = useRootState()
  let content: React.ReactNode = null

  const user = useUser()
  switch (user.type) {
    case 'loading':
      content = <LoadingScreen />
      break

    case 'authenticated':
      if (!user.data.acls.includes(ACL.CALL_CENTER)) {
        content = <UnauthorizedScreen />
      }
      break

    case 'unauthenticated':
      content = <LoginScreen />
      break

    default:
      break
  }

  const uiState = state.uiState

  if (!content)
    switch (uiState.type) {
      case 'init':
        content = <InitialScreen onNewSession={actions.startSession} />
        break

      case 'session':
        if (!state.currentSession) throw new Error('No current session')

        content = (
          <Session
            {...actions.currentSession}
            setScreenStateValue={actions.currentSession.setLoadedState}
            value={state.currentSession}
            onEnd={actions.endSession}
          />
        )
        break
    }

  return <AppShell>{content}</AppShell>
}

export { CallCenter }
