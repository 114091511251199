import React, { useState } from 'react'
import {
  ScreenTitle,
  ScreenDescription,
  PrimaryButton,
  Input as InputField,
  ScreenErrorMessage
} from '../../ui/common'
import { COPY } from 'src/frontoffice/common/copy'
import { State } from './state'
import { Maybe } from 'src/types/utils'
import { PointerData } from 'src/types/data'

interface NumberInputProps {
  value: State
  setValue: (v: State) => void
  onContinue: (next: Maybe<PointerData>) => void
}

const NumberInput: React.FC<NumberInputProps> = ({
  value,
  setValue,
  onContinue
}) => {
  const screen = value.screen

  const [error, setError] = useState(false)

  function validateInput() {
    const hasError =
      typeof value.value !== 'number' ||
      isNaN(value.value) ||
      value.value < (screen?.min || -Infinity) ||
      value.value > (screen?.max || Infinity)
    setError(hasError)
    return hasError
  }

  return (
    <div className="screen-screen">
      <ScreenTitle>{screen.title}</ScreenTitle>
      <ScreenDescription>{screen.text}</ScreenDescription>
      <InputField
        type="number"
        min={screen.min}
        max={screen.max}
        placeholder={screen.placeholder}
        value={value.value || ''}
        onChange={(e: any) =>
          setValue({ ...value, value: Number.parseFloat(e.target.value) })
        }
      />
      {error && (
        <ScreenErrorMessage>
          {COPY.NUMBER_INPUT_ERROR_MESSAGE.replace(
            '{min}',
            screen.min + ''
          ).replace('{max}', screen.max + '')}
        </ScreenErrorMessage>
      )}
      <div className="buttons-group">
        <PrimaryButton
          onClick={() => {
            if (!validateInput()) onContinue(screen.pointer)
          }}
        >
          {COPY.CONTINUE}
        </PrimaryButton>
      </div>
    </div>
  )
}

export { NumberInput }
