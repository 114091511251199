import React, { Fragment, FC } from 'react'
import { DropdownOption } from './DropdownOption'

interface DropdownOptionObj {
  value: string
  text: string
}

interface DropdownOptionsListProps {
  options: DropdownOptionObj[]
  onChange: (value: string) => void
}

export const DropdownOptionsList: FC<DropdownOptionsListProps> = ({
  options,
  onChange
}) => {
  return (
    <Fragment>
      {options.map((option, i) => (
        <DropdownOption option={option} key={i} onChange={onChange} />
      ))}
    </Fragment>
  )
}
