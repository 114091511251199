import { SessionReducerState } from '../../store/types';
import {
  ImageData,
  MechanicScreenData,
  PointerData,
  ScreenType,
  SummaryEntry
} from 'src/types/data';
import { Maybe } from 'src/types/utils';

export interface State {
  type: typeof ScreenType.MECHANIC;
  screen: MechanicScreenData;
  nextPointer?: Maybe<PointerData>;
}

function getInitialState(screen: MechanicScreenData): State {
  return {
    type: ScreenType.MECHANIC,
    screen,
    nextPointer: undefined
  };
}

const getNextScreenPointer = (state: State): Maybe<PointerData> => {
  if (!state.nextPointer) throw new Error('No next pointer');
  return state.nextPointer;
};

function getSummary(
  state: State,
  sessionState: SessionReducerState
): SummaryEntry[] | null {
  return null;
}

function getImages(state: State): ImageData[] {
  return state.screen.images;
}

export { getInitialState, getNextScreenPointer, getSummary, getImages };
