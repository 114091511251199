import React, { useEffect, useState } from 'react'
import { getInstallationCodes } from '../../sandbox'
import {
  ScreenTitle,
  Dropdown,
  PrimaryButton,
  SecondaryButton,
  ScreenDescription
} from '../../ui/common'
import { AutocompleteDropdown } from '../../ui/common/autocomplete-dropdown/AutocompleteDropdown'
import useSessionState from '../../store/useSessionState'
import { COPY } from 'src/frontoffice/common/copy'
import { State } from './state'
import { AsyncDataState } from 'src/types/utils'
import { InstallationCodeData } from 'src/types/data'

let initialInstallationCodesState: AsyncDataState<InstallationCodeData[]> = {
  id: 'loading'
}

interface InstallationCodeSelectorProps {
  value: State
  setValue: (v: State) => void
  onContinue: () => void
}

function InstallationCodeSelector({
  value,
  setValue,
  onContinue
}: InstallationCodeSelectorProps) {
  const { state: sessionData, actions: sessionActions } = useSessionState()
  const installationId = sessionData.device
    ? sessionData.device.installationId
    : undefined
  const { codeId } = value
  const [codesState, setCodesState] = useState(initialInstallationCodesState)

  if (!installationId) throw new Error('installationId not found')

  const setCodeId = (codeId?: string) => {
    if (!codeId) return
    if (codesState.id !== 'ready') return

    const code = codesState.data.find(f => f.id === codeId)

    if (!code) return

    sessionActions.setQueuedPointer({
      pointer: value.screen.yesPointer,
      start: sessionData.history.length,
      end: undefined
    })

    setValue({
      ...value,
      codeId,
      codePointer: code.pointer,
      codeName: codeId === 'no' ? 'No Code' : code.name
    })
  }

  useEffect(() => {
    if (codesState.id === 'loading') {
      getInstallationCodes(installationId).then(
        codes => {
          setCodesState({ id: 'ready', data: codes })
        },
        error => {
          setCodesState({ id: 'error', error })
        }
      )
    }
  }, [codesState.id, installationId])

  useEffect(() => {
    if (value.screen.askForCode === false)
      setValue({ ...value, selectedAnswer: 'yes' })
  }, [value, setValue])

  return (
    <div className="device-selector">
      <div className="device-selector__select">
        <ScreenTitle>
          {value.screen.title ||
            (value.screen.askForCode ? COPY.SELECT_CODE : COPY.SEARCH_FOR_CODE)}
        </ScreenTitle>
        <ScreenDescription> </ScreenDescription>
        {value.screen.askForCode && (
          <div className="buttons-group">
            <SecondaryButton
              className={value.selectedAnswer === 'no' ? 'selected' : ''}
              onClick={() => {
                setValue({ ...value, selectedAnswer: 'no' })
              }}
            >
              {value.screen.noText || COPY.NO}
            </SecondaryButton>
            <SecondaryButton
              className={value.selectedAnswer === 'yes' ? 'selected' : ''}
              onClick={() => {
                setValue({ ...value, selectedAnswer: 'yes' })
              }}
            >
              {value.screen.yesText || COPY.YES}
            </SecondaryButton>
            {(value.screen.answers || []).map(answer => (
              <SecondaryButton
                key={answer.id}
                className={value.selectedAnswer === answer.id ? 'selected' : ''}
                onClick={() =>
                  setValue({ ...value, selectedAnswer: answer.id })
                }
              >
                {answer.title}
              </SecondaryButton>
            ))}
          </div>
        )}
        {value.selectedAnswer === 'yes' && (
          <AutocompleteDropdown
            autoFocus
            onChange={setCodeId}
            value={codeId}
            placeholder={value.screen.title || COPY.SEARCH_FOR_CODE}
            options={
              codesState.id === 'ready'
                ? codesState.data.map(c => ({
                    value: c.id,
                    text: c.name,
                    disabled: false,
                    selected: false
                  }))
                : []
            }
          />
        )}
      </div>
      {(codeId || (value.selectedAnswer && value.selectedAnswer !== 'yes')) && (
        <div className="buttons-group">
          <PrimaryButton
            className="no-reset primary"
            onClick={() => onContinue()}
          >
            {COPY.CONTINUE}
          </PrimaryButton>
        </div>
      )}
    </div>
  )
}

export { InstallationCodeSelector }
