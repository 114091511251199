import React, { Fragment } from 'react'
import {
  ScreenTitle,
  SecondaryButton,
  ScreenDescription
} from '../../ui/common'
import { State } from './state'

interface ButtonsQuestionProps {
  value: State
  setValue: (v: State) => void
  onContinue: () => void
}

const ButtonsQuestion: React.FC<ButtonsQuestionProps> = ({
  value,
  setValue,
  onContinue
}) => {
  const question = value.screen
  const selectedAnswerObj = question.answers.find(
    a => a.id === value.selectedAnswerId
  )

  return (
    <div className="screen-buttons-question">
      <ScreenTitle>{question.title}</ScreenTitle>
      <ScreenDescription>{question.text}</ScreenDescription>
      <div className="buttons-group">
        {question.answers.map(answer => (
          <SecondaryButton
            disabled={!answer.pointer}
            className={value.selectedAnswerId === answer.id ? 'selected' : ''}
            key={answer.id}
            onClick={() => {
              setValue({ ...value, selectedAnswerId: answer.id })
              console.log('onClick', answer.id, answer.pointer)
              onContinue()
            }}
          >
            {answer.title}
          </SecondaryButton>
        ))}
      </div>
    </div>
  )
}

export { ButtonsQuestion }
