import { SessionReducerState } from '../../store/types';
import {
  SummaryEntry,
  ButtonsQuestionScreenData,
  ImageData,
  PointerData,
  ScreenType
} from 'src/types/data';

export type State = {
  type: typeof ScreenType.BUTTONS_QUESTION;
  screen: ButtonsQuestionScreenData;
  selectedAnswerId: string | undefined;
};

function getInitialState(screen: ButtonsQuestionScreenData): State {
  return {
    type: ScreenType.BUTTONS_QUESTION,
    selectedAnswerId: undefined,
    screen
  };
}

const getNextScreenPointer = (state: State): PointerData | undefined => {
  if (!state.selectedAnswerId) throw new Error('No answer selected');
  const answer = state.screen.answers.find(
    answer => answer.id === state.selectedAnswerId
  );
  if (!answer) throw new Error('Answer not found');
  return answer.pointer;
};

function getSummary(
  state: State,
  sessionState: SessionReducerState
): SummaryEntry[] {
  return [
    {
      title: state.screen.title || '',
      value:
        state.screen.answers.find(
          answer => answer.id === state.selectedAnswerId
        )?.title || ''
    }
  ];
}

function getImages(state: State): ImageData[] {
  return state.screen.images;
}

export { getInitialState, getNextScreenPointer, getSummary, getImages };
