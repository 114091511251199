export const COPY_EN = {
  BACK: 'Back',
  END_SESSION: 'End Session',
  NEW_SESSION: 'New Session',
  LOGIN: 'Login',
  LOGOUT: 'Log out',
  START_SESSION_TITLE: 'Start a new Session',
  START_SESSION_DESCRIPTION: 'Start a new session to help a customer',
  COPY: 'Copy',
  COPIED: 'Copied!',
  FABRICANT: 'Fabricant',
  INSTALLATION: 'Installation',
  INSTALLATION_CODE: 'Installation Code',
  SELECT_INSTALLATION: 'Select an Installation',
  SEARCH_FOR_FABRICANT: 'Search for a Fabricant',
  SEARCH_FOR_INSTALLATION: 'Search for an Installation',
  SELECT_CODE: 'Select a Code',
  SEARCH_FOR_CODE: 'Search for a Code',
  NO_RESULTS: 'No results',
  SEND_TO_MECHANIC: 'Send to Mechanic',
  SEND_TO_MECHANIC_DESCRIPTION: 'Make an appointment with a mechanic.',
  SOLVED: 'Solved',
  SOLVED_DESCRIPTION: 'The problem is solved.',
  NO: 'No',
  YES: 'Yes',
  SUMMARY: 'Summary',
  EXTRA_SUMMARY: 'Old Summary',
  CONTINUE: 'Continue',
  FEEDBACK: 'Feedback',
  FEEDBACK_TITLE: 'Please write feedback for this question',
  FEEDBACK_PLACEHOLDER: 'Write your feedback here...',
  SENDING: 'Sending...',
  FEEDBACK_ERROR:
    'There was an error sending your feedback. Please try again later.',
  CANCEL: 'Cancel',
  SEND: 'Send',
  TOOL_NAME: 'NOS',
  SHOW_IMAGES: 'Show images',
  NUMBER_INPUT_ERROR_MESSAGE: 'Please enter a number between {min} and {max}',
  DROPDOWN_PLACEHOLDER: 'Select an option',
  COSTS_DISCUSSED: 'Costs discussed',
  RESET: 'Reset',
  LOADING: 'Loading...',
  UNAUTHORIZED: 'Unauthorized',
  UNAUTHORIZED_DESCRIPTION: 'You are not authorized to access this page.',
  LOGIN_TITLE: 'Login to NOS',
  LOGIN_BUTTON_TEXT: 'Login with SSO',
  RELEASES: 'Releases'
};

export const COPY_NL = {
  BACK: 'Terug',
  END_SESSION: 'Sessie beëindigen',
  NEW_SESSION: 'Nieuwe sessie',
  LOGIN: 'Inloggen',
  LOGOUT: 'Uitloggen',
  START_SESSION_TITLE: 'Start een nieuwe sessie',
  START_SESSION_DESCRIPTION: 'Start een nieuwe sessie om een klant te helpen',
  COPY: 'Kopiëren',
  COPIED: 'Gekopieerd!',
  FABRICANT: 'Fabrikant',
  INSTALLATION: 'Installatie',
  INSTALLATION_CODE: 'Installatie Code',
  SELECT_INSTALLATION: 'Selecteer een installatie',
  SEARCH_FOR_FABRICANT: 'Zoek een fabrikant',
  SEARCH_FOR_INSTALLATION: 'Zoek een installatie',
  SELECT_CODE: 'Ziet u een code op het display?',
  SEARCH_FOR_CODE: 'Zoek een code',
  NO_RESULTS: 'Geen resultaten',
  SEND_TO_MECHANIC: 'Stuur naar monteur',
  SEND_TO_MECHANIC_DESCRIPTION: 'Maak een afspraak met een monteur.',
  SOLVED: 'Opgelost',
  SOLVED_DESCRIPTION: 'Het probleem is opgelost.',
  NO: 'Nee',
  YES: 'Ja',
  SUMMARY: 'Samenvatting storing uitvraag',
  EXTRA_SUMMARY: 'Samenvatting SMS',
  CONTINUE: 'Doorgaan',
  FEEDBACK: 'Feedback',
  FEEDBACK_TITLE: 'Geef feedback over deze vraag',
  FEEDBACK_PLACEHOLDER: 'Schrijf hier uw feedback...',
  SENDING: 'Versturen...',
  FEEDBACK_ERROR:
    'Er is een fout opgetreden bij het verzenden van uw feedback. Probeer het later opnieuw.',
  CANCEL: 'Annuleren',
  SEND: 'Verzenden',
  TOOL_NAME: 'NOS',
  SHOW_IMAGES: 'Toon afbeeldingen',
  NUMBER_INPUT_ERROR_MESSAGE: 'Voer een getal in tussen {min} en {max}',
  DROPDOWN_PLACEHOLDER: 'Selecteer een optie',
  COSTS_DISCUSSED: 'Kosten besproken',
  RESET: 'Gereset',
  LOADING: 'Laden...',
  UNAUTHORIZED: 'Ongeautoriseerd',
  UNAUTHORIZED_DESCRIPTION:
    'U bent niet geautoriseerd om deze pagina te bekijken.',
  LOGIN_TITLE: 'Inloggen bij NOS',
  LOGIN_BUTTON_TEXT: 'Inloggen met SSO',
  RELEASES: 'Releases'
};

export const COPY = COPY_NL;
