import React, { useEffect, useState } from 'react'
import { getSpecials, getSubSpecials } from '../../sandbox'
import { ScreenTitle, PrimaryButton, ScreenDescription } from '../../ui/common'
import { AutocompleteDropdown } from '../../ui/common/autocomplete-dropdown/AutocompleteDropdown'
import { COPY } from 'src/frontoffice/common/copy'
import { State } from './state'
import { AsyncDataState } from 'src/types/utils'
import { MainSpecialData, SubSpecialData } from 'src/types/data'

let initialSpecialsState: AsyncDataState<MainSpecialData[]> = {
  id: 'loading'
}

let initialSubSpecialsState: AsyncDataState<SubSpecialData[]> = {
  id: 'loading'
}

interface SpecialSelectorProps {
  value: State
  setValue: (v: State) => void
  onContinue: () => void
}

function SpecialSelector({
  value,
  setValue,
  onContinue
}: SpecialSelectorProps) {
  const {
    mainSpecialId = undefined,
    mainSpecialName = undefined,
    subSpecialId = undefined
  } = value || {}

  const setSpecialId = (mainSpecialId?: string) => {
    if (mainSpecialsState.id !== 'ready') return
    const mainSpecial = mainSpecialsState.data.find(f => f.id === mainSpecialId)
    setValue({
      ...value,
      mainSpecialId: mainSpecial ? mainSpecial.id : undefined,
      mainSpecialName: mainSpecial ? mainSpecial.name : undefined,
      subSpecialId: undefined,
      subSpecialName: undefined
    })
  }

  const setSubSpecialId = (subSpecialId?: string) => {
    if (subSpecialsState.id !== 'ready') return
    const subSpecial = subSpecialsState.data.find(i => i.id === subSpecialId)
    setValue({
      ...value,
      selectedImages: subSpecial ? subSpecial.images : [],
      mainSpecialId,
      mainSpecialName,
      subSpecialId: subSpecial ? subSpecial.id : undefined,
      subSpecialName: subSpecial ? subSpecial.name : undefined
    })
  }

  const [mainSpecialsState, setSpecialsState] = useState(initialSpecialsState)
  const [subSpecialsState, setSubSpecialsState] = useState(
    initialSubSpecialsState
  )

  useEffect(() => {
    if (mainSpecialsState.id === 'loading') {
      getSpecials().then(
        mainSpecials => {
          setSpecialsState({ id: 'ready', data: mainSpecials })
        },
        error => {
          setSpecialsState({ id: 'error', error })
        }
      )
    }
  }, [mainSpecialsState.id])

  useEffect(() => {
    if (mainSpecialId) {
      setSubSpecialsState(initialSubSpecialsState)
      getSubSpecials(mainSpecialId).then(
        subSpecials => {
          setSubSpecialsState({ id: 'ready', data: subSpecials })
        },
        error => {
          setSubSpecialsState({ id: 'error', error })
        }
      )
    }
  }, [mainSpecialId])

  const finalSpecialId =
    mainSpecialsState.id === 'ready' &&
    mainSpecialsState.data.find(f => f.id === mainSpecialId)
      ? mainSpecialId
      : ''

  return (
    <div className="device-selector">
      <ScreenTitle>
        {value.screen.title || COPY.SELECT_INSTALLATION}
      </ScreenTitle>
      <ScreenDescription> </ScreenDescription>
      <div className="device-selector__select">
        <AutocompleteDropdown
          autoFocus
          placeholder={
            value.screen.mainDropdownPlaceholder || COPY.SEARCH_FOR_FABRICANT
          }
          value={finalSpecialId}
          onChange={setSpecialId}
          options={
            mainSpecialsState.id === 'ready'
              ? mainSpecialsState.data.map(f => ({
                  text: f.name,
                  value: f.id,
                  disabled: false,
                  selected: false
                }))
              : []
          }
        />
      </div>
      <div className="device-selector__select">
        <AutocompleteDropdown
          placeholder={
            value.screen.subDropdownPlaceholder || COPY.SEARCH_FOR_INSTALLATION
          }
          value={
            finalSpecialId &&
            subSpecialsState.id === 'ready' &&
            subSpecialsState.data.find(f => f.id === subSpecialId)
              ? subSpecialId
              : ''
          }
          onChange={setSubSpecialId}
          options={
            finalSpecialId && subSpecialsState.id === 'ready'
              ? subSpecialsState.data.map(f => ({
                  text: f.name,
                  value: f.id,
                  disabled: false,
                  selected: false
                }))
              : []
          }
        />
      </div>
      {mainSpecialId && subSpecialId && (
        <div className="buttons-group">
          <PrimaryButton onClick={() => onContinue()}>
            {COPY.CONTINUE}
          </PrimaryButton>
        </div>
      )}
    </div>
  )
}

export { SpecialSelector }
