import React, { useState, useEffect, useReducer, useRef } from 'react';

function render(
  content: React.ReactNode | (() => React.ReactNode)
): React.ReactNode {
  return typeof content === 'function' ? content() : content;
}

export const useContentVisibility = (
  shownContent: React.ReactNode | (() => React.ReactNode),
  hiddenContent: React.ReactNode | (() => React.ReactNode) = null,
  initialValue = false
): [React.ReactNode, () => void, () => void] => {
  const [state, setState] = useState(initialValue);

  return [
    state ? render(shownContent) : render(hiddenContent),
    () => setState(true),
    () => setState(false)
  ];
};

export type Thunk<S, A extends (...args: any) => any> = (
  state: S,
  actions: ReturnType<A>
) => void;

export type DispatcherWithThunk<A> = (d: A) => void;

export function useReducerWithThunk<A, S, F>({
  reducer,
  initialState,
  generateActions
}: {
  reducer: (s: S, a: A) => S;
  initialState: S;
  generateActions: (dispatch: React.Dispatch<A>) => F;
}): [S, F, React.Dispatch<A>] {
  const [state, dispatch] = useReducer(reducer, initialState);

  let customDispatch: DispatcherWithThunk<A> = action => {
    if (typeof action === 'function') {
      return action(state, actions, customDispatch);
    } else {
      dispatch(action);
    }
  };

  const actions = generateActions(customDispatch);

  return [state, actions, dispatch];
}

export const useEventListener = (
  eventName: string,
  handler: (event: Event) => void,
  element: HTMLElement | Window = window
): void => {
  const savedHandler = useRef<(event: Event) => void>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    const eventListener = (event: Event) =>
      savedHandler.current && savedHandler.current(event);

    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
};
