import { COPY } from 'src/frontoffice/common/copy';
import {
  SummaryEntry,
  ImageData,
  PointerData,
  SubSpecialCodeSelectorScreenData,
  ScreenType
} from 'src/types/data';
import { SessionReducerState } from '../../store/types';
import { Maybe } from 'src/types/utils';

export interface State {
  type: typeof ScreenType.SUB_SPECIAL_CODE_SELECTOR;
  screen: SubSpecialCodeSelectorScreenData;
  selectedAnswer: 'yes' | 'no' | string | undefined;
  codeId: string | undefined;
  codeName: string | undefined;
  codePointer: Maybe<PointerData>;
}

function getInitialState(screen: SubSpecialCodeSelectorScreenData): State {
  return {
    type: ScreenType.SUB_SPECIAL_CODE_SELECTOR,
    screen,
    selectedAnswer: undefined,
    codeId: undefined,
    codePointer: undefined,
    codeName: undefined
  };
}

const getNextScreenPointer = (state: State): Maybe<PointerData> => {
  return state.codePointer!;
};

function getSummary(
  state: State,
  sessionState: SessionReducerState
): SummaryEntry[] {
  return [
    {
      title: COPY.INSTALLATION_CODE,
      value: state.codeName!
    }
  ];
}

function getImages(state: State): ImageData[] {
  return state.screen.images;
}

export { getInitialState, getNextScreenPointer, getSummary, getImages };
