import React, { Fragment, ReactElement } from 'react'
import { COPY } from './common/copy'
import {
  Icon,
  ScreenDescription,
  ScreenTitle,
  SecondaryButton
} from './ui/common'

const UnauthorizedScreen: React.FC = (): ReactElement => {
  return (
    <Fragment>
      <Icon
        className="background-image"
        src="/svgs/background.svg"
        alt="background"
      />
      <div className="init">
        <div className="central-box">
          <ScreenTitle>{COPY.UNAUTHORIZED}</ScreenTitle>
          <ScreenDescription>{COPY.UNAUTHORIZED_DESCRIPTION}</ScreenDescription>
          <div className="controls">
            <SecondaryButton
              onClick={() => {
                window.location.href = '/api/auth/logout?redirectTo=/'
              }}
            >
              {COPY.LOGOUT}
            </SecondaryButton>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export { UnauthorizedScreen }
