import React, { useEffect, useState } from 'react'
import {
  PrimaryButton,
  SecondaryButton,
  ScreenSubtitle,
  ScreenDescription
} from './common'
import { sendFeedback } from '../sandbox'
import { COPY } from 'src/frontoffice/common/copy'
import { buildSummary } from '../utils'
import useSessionState from '../store/useSessionState'
import { ScreenData } from 'src/types/data'
import { useUser } from '../contexts/UserContext'

interface FeedbackPopupProps {
  screen: ScreenData
  onClose: () => void
}

function FeedbackPopup({ screen, onClose }: FeedbackPopupProps) {
  const user = useUser()
  const [feedback, setFeedback] = useState('')
  const [requestStatus, setRequestStatus] = useState('idle')
  const { state: sessionState } = useSessionState()
  const { summary, extraSummary } = buildSummary(sessionState)

  const submitForm = () => {
    if (user.type !== 'authenticated') {
      console.error('User is not authenticated')
      setRequestStatus('error')
      return
    }
    setRequestStatus('pending')
    sendFeedback({
      user: user.data.email,
      screen,
      feedback,
      summary: summary.concat(`\n\n${extraSummary}`)
    }).then(
      () => {
        setRequestStatus('idle')
        onClose()
      },
      () => {
        setRequestStatus('error')
      }
    )
  }

  useEffect(() => {
    if (requestStatus === 'error')
      setTimeout(() => setRequestStatus('idle'), 3000)
  }, [requestStatus])

  return (
    <div className="feedback-popup-container">
      <div className="feedback-popup">
        <div className="feedback-popup-header">
          <ScreenSubtitle>{COPY.FEEDBACK_TITLE}</ScreenSubtitle>
          <ScreenDescription>{screen.title}</ScreenDescription>
        </div>
        <div className="feedback-popup-body">
          <textarea
            placeholder={COPY.FEEDBACK_PLACEHOLDER}
            disabled={requestStatus === 'pending'}
            value={feedback}
            onChange={evt => setFeedback(evt.target.value)}
            className="no-reset feedback-popup-textarea"
          />
        </div>
        <div className="feedback-popup-footer">
          <PrimaryButton
            disabled={requestStatus === 'pending'}
            onClick={submitForm}
          >
            {requestStatus === 'pending' ? COPY.SENDING : COPY.SEND}
          </PrimaryButton>
          <SecondaryButton
            disabled={requestStatus === 'pending'}
            onClick={onClose}
          >
            {COPY.CANCEL}
          </SecondaryButton>
        </div>
        {requestStatus === 'error' && (
          <div className="no-reset feedback-popup-error">
            {COPY.FEEDBACK_ERROR}
          </div>
        )}
      </div>
    </div>
  )
}

export { FeedbackPopup }
