import React from 'react'

interface CheckboxProps {
  checked: boolean
  onChange: (v: boolean) => void
  label: string
}

const Checkbox: React.FC<CheckboxProps> = ({ checked, onChange, label }) => {
  return (
    <div className="feenstra-nos-checkbox">
      <input
        type="checkbox"
        checked={checked}
        onChange={e => onChange(e.target.checked)}
      />
      <label onClick={() => onChange(!checked)}>{label}</label>
    </div>
  )
}

export { Checkbox }
