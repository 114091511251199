import React, { Fragment, ReactElement } from 'react'
import { COPY } from './common/copy'
import {
  Icon,
  PrimaryButton,
  ScreenDescription,
  ScreenTitle,
  SecondaryButton
} from './ui/common'

interface InitialScreenProps {
  onNewSession: () => void
}

const InitialScreen: React.FC<InitialScreenProps> = ({
  onNewSession
}): ReactElement => {
  return (
    <Fragment>
      <Icon
        className="background-image"
        src="/svgs/background.svg"
        alt="background"
      />
      <div className="init">
        <div className="central-box">
          <ScreenTitle>{COPY.START_SESSION_TITLE}</ScreenTitle>
          <ScreenDescription>
            {COPY.START_SESSION_DESCRIPTION}
          </ScreenDescription>
          <div className="controls">
            <PrimaryButton onClick={onNewSession}>
              {COPY.NEW_SESSION}
            </PrimaryButton>
            <SecondaryButton
              onClick={() => {
                window.location.href = '/api/auth/logout?redirectTo=/'
              }}
            >
              {COPY.LOGOUT}
            </SecondaryButton>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export { InitialScreen }
