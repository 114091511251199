import React, { Fragment, useState } from 'react'
import useSessionState from '../../store/useSessionState'
import { getImages } from '../../utils'
import { GalleryButton, Icon } from '.'
import { useContentVisibility } from 'src/lib/hooks'
import { ImageData } from '@/types/data'

interface FullscreenImageProps {
  images: ImageData[]
  onClose: () => void
}

function ImageGallery(): JSX.Element {
  const { state } = useSessionState()

  const images = getImages(state)

  const [fullscreenImage, showFullscreenImage, hideFullscreenImage] =
    useContentVisibility(
      () =>
        images.length > 0 && (
          <FullscreenImage
            images={images}
            onClose={() => hideFullscreenImage()}
          />
        )
    )

  return (
    <Fragment>
      {fullscreenImage}
      <div className="image-gallery">
        <GalleryButton
          numberOfImages={images.length}
          onClick={() => showFullscreenImage()}
        />
      </div>
    </Fragment>
  )
}

function FullscreenImage({
  images,
  onClose
}: FullscreenImageProps): JSX.Element {
  const [currentIndex, setCurrentIndex] = useState<number>(images.length - 1)
  const currentImageUrl = images[currentIndex].url

  return (
    <div className="fullscreen-image" onClick={() => onClose()}>
      <div
        className="image-container"
        onClick={event => event.stopPropagation()}
      >
        <img alt={currentImageUrl} src={currentImageUrl} />
      </div>
      <Icon
        onClick={() => onClose()}
        className="zoom-out-icon"
        alt="Zoom Out"
        src="/svgs/zoomout.svg"
      />

      {images.length > 1 && (
        <>
          <div className="image-controls">
            <Icon
              onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                event.stopPropagation()
                setCurrentIndex(
                  currentIndex - 1 < 0 ? images.length - 1 : currentIndex - 1
                )
              }}
              className="previous-icon"
              alt="Previous Image"
              src="/svgs/back.svg"
            />
            <Icon
              onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                event.stopPropagation()
                setCurrentIndex((currentIndex + 1) % images.length)
              }}
              className="next-icon"
              alt="Next Image"
              src="/svgs/back.svg"
            />
          </div>
          <div
            className="image-pagination"
            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
              event.stopPropagation()
            }}
          >
            {images.map((_image, index) => (
              <div
                onClick={() => {
                  setCurrentIndex(index)
                }}
                key={'image-pagination-' + index}
                className={`pagination-bullet ${
                  index === currentIndex ? 'active-bullet' : 'inactive-bullet'
                }`}
              ></div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export { ImageGallery }
