import React from 'react'
import { ScreenType } from '../../types/data'
import {
  ScreenComponentIntstanceType,
  ScreenComponentType,
  screens
} from '../screens'
import { ScreenState, SessionReducerState } from '../store/types'

interface ScreenProps {
  value: ScreenState
  setValue: (v: ScreenState) => void
  session: SessionReducerState
  onPrevious: () => void
  onContinue: () => void
}

function Screen({
  value,
  setValue,
  session,
  onPrevious,
  onContinue
}: ScreenProps) {
  return (
    <div className="screen">
      <ScreenComponent
        key={value.screen.id}
        value={value}
        session={session}
        setValue={setValue}
        onContinue={onContinue}
        onPrevious={onPrevious}
      />
    </div>
  )
}

function ScreenComponent({
  value,
  setValue,
  session,
  onPrevious,
  onContinue
}: ScreenProps): ScreenComponentIntstanceType {
  let ScreenComponent: ScreenComponentType

  switch (value.type) {
    case ScreenType.BUTTONS_QUESTION:
      ScreenComponent = screens[ScreenType.BUTTONS_QUESTION].Component
      return (
        <ScreenComponent
          key={value.screen.id}
          value={value}
          setValue={setValue}
          onContinue={onContinue}
        />
      )
    case ScreenType.DEVICE_SELECTOR:
      ScreenComponent = screens[ScreenType.DEVICE_SELECTOR].Component
      return (
        <ScreenComponent
          key={value.screen.id}
          value={value}
          setValue={setValue}
          onContinue={onContinue}
        />
      )
    case ScreenType.CALAMITY_SELECTOR:
      ScreenComponent = screens[ScreenType.CALAMITY_SELECTOR].Component
      return (
        <ScreenComponent
          key={value.screen.id}
          value={value}
          setValue={setValue}
          onContinue={onContinue}
        />
      )
    case ScreenType.DROPDOWN_QUESTION:
      ScreenComponent = screens[ScreenType.DROPDOWN_QUESTION].Component
      return (
        <ScreenComponent
          key={value.screen.id}
          value={value}
          setValue={setValue}
          onContinue={onContinue}
        />
      )
    case ScreenType.TEXT_INPUT:
      ScreenComponent = screens[ScreenType.TEXT_INPUT].Component
      return (
        <ScreenComponent
          key={value.screen.id}
          value={value}
          setValue={setValue}
          onContinue={onContinue}
        />
      )
    case ScreenType.NUMBER_INPUT:
      ScreenComponent = screens[ScreenType.NUMBER_INPUT].Component
      return (
        <ScreenComponent
          key={value.screen.id}
          value={value}
          setValue={setValue}
          onContinue={onContinue}
        />
      )
    case ScreenType.END:
      ScreenComponent = screens[ScreenType.END].Component
      return (
        <ScreenComponent
          key={value.screen.id}
          value={value}
          setValue={setValue}
          onContinue={onContinue}
        />
      )
    case ScreenType.INSTALLATION_CODE_SELECTOR:
      ScreenComponent = screens[ScreenType.INSTALLATION_CODE_SELECTOR].Component
      return (
        <ScreenComponent
          key={value.screen.id}
          value={value}
          setValue={setValue}
          onContinue={onContinue}
        />
      )
    case ScreenType.INSTRUCTION:
      ScreenComponent = screens[ScreenType.INSTRUCTION].Component
      return (
        <ScreenComponent
          key={value.screen.id}
          value={value}
          setValue={setValue}
          onContinue={onContinue}
        />
      )
    case ScreenType.MECHANIC:
      ScreenComponent = screens[ScreenType.MECHANIC].Component
      return (
        <ScreenComponent
          key={value.screen.id}
          value={value}
          setValue={setValue}
          onContinue={onContinue}
        />
      )

    case ScreenType.SPECIAL_SELECTOR:
      ScreenComponent = screens[ScreenType.SPECIAL_SELECTOR].Component
      return (
        <ScreenComponent
          key={value.screen.id}
          value={value}
          setValue={setValue}
          onContinue={onContinue}
        />
      )

    case ScreenType.SUB_SPECIAL_CODE_SELECTOR:
      ScreenComponent = screens[ScreenType.SUB_SPECIAL_CODE_SELECTOR].Component
      return (
        <ScreenComponent
          key={value.screen.id}
          value={value}
          setValue={setValue}
          onContinue={onContinue}
        />
      )
  }
}

export { Screen }
