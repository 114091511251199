import React, { useEffect, useState } from 'react'
import { getFabricants, getInstallations } from '../../sandbox'
import { ScreenTitle, PrimaryButton, ScreenDescription } from '../../ui/common'
import { AutocompleteDropdown } from '../../ui/common/autocomplete-dropdown/AutocompleteDropdown'
import useSessionState from '../../store/useSessionState'
import { COPY } from 'src/frontoffice/common/copy'
import { State } from './state'
import { AsyncDataState } from 'src/types/utils'
import { FabricantData, InstallationData } from 'src/types/data'

let initialFabricantsState: AsyncDataState<FabricantData[]> = {
  id: 'loading'
}

let initialInstallationsState: AsyncDataState<InstallationData[]> = {
  id: 'loading'
}

interface DeviceSelectorProps {
  value: State
  setValue: (v: State) => void
  onContinue: () => void
}

function DeviceSelector({ value, setValue, onContinue }: DeviceSelectorProps) {
  const { state, actions } = useSessionState()
  const {
    fabricantId = undefined,
    fabricantName = undefined,
    installationId = undefined
  } = state.device || {}

  const setFabricantId = (fabricantId?: string) => {
    if (fabricantsState.id !== 'ready') return
    const fabricant = fabricantsState.data.find(f => f.id === fabricantId)
    actions.setDevice({
      fabricantId: fabricant ? fabricant.id : undefined,
      fabricantName: fabricant ? fabricant.name : undefined,
      installationId: undefined,
      installationName: undefined
    })
  }

  const setInstallationId = (installationId?: string) => {
    if (installationsState.id !== 'ready') return
    const installation = installationsState.data.find(
      i => i.id === installationId
    )
    setValue({
      ...value,
      selectedInstallationImages: installation ? installation.images : []
    })
    actions.setDevice({
      fabricantId,
      fabricantName,
      installationId: installation ? installation.id : undefined,
      installationName: installation ? installation.name : undefined
    })
  }

  const [fabricantsState, setFabricantsState] = useState(initialFabricantsState)
  const [installationsState, setInstallationsState] = useState(
    initialInstallationsState
  )

  useEffect(() => {
    if (fabricantsState.id === 'loading') {
      getFabricants().then(
        fabricants => {
          setFabricantsState({ id: 'ready', data: fabricants })
        },
        error => {
          setFabricantsState({ id: 'error', error })
        }
      )
    }
  }, [fabricantsState.id])

  useEffect(() => {
    if (fabricantId) {
      setInstallationsState(initialInstallationsState)
      getInstallations(fabricantId).then(
        installations => {
          setInstallationsState({ id: 'ready', data: installations })
        },
        error => {
          setInstallationsState({ id: 'error', error })
        }
      )
    }
  }, [fabricantId])

  const finalFabricantId =
    fabricantsState.id === 'ready' &&
    fabricantsState.data.find(f => f.id === fabricantId)
      ? fabricantId
      : ''

  return (
    <div className="device-selector">
      <ScreenTitle>
        {value.screen.title || COPY.SELECT_INSTALLATION}
      </ScreenTitle>
      <ScreenDescription> </ScreenDescription>
      <div className="device-selector__select">
        <AutocompleteDropdown
          autoFocus
          placeholder={
            value.screen.fabricantDropdownPlaceholder ||
            COPY.SEARCH_FOR_FABRICANT
          }
          value={finalFabricantId}
          onChange={setFabricantId}
          options={
            fabricantsState.id === 'ready'
              ? fabricantsState.data.map(f => ({
                  text: f.name,
                  value: f.id,
                  disabled: false,
                  selected: false
                }))
              : []
          }
        />
      </div>
      <div className="device-selector__select">
        <AutocompleteDropdown
          placeholder={
            value.screen.installationDropdownPlaceholder ||
            COPY.SEARCH_FOR_INSTALLATION
          }
          value={
            finalFabricantId &&
            installationsState.id === 'ready' &&
            installationsState.data.find(f => f.id === installationId)
              ? installationId
              : ''
          }
          onChange={setInstallationId}
          options={
            finalFabricantId && installationsState.id === 'ready'
              ? installationsState.data.map(f => ({
                  text: f.name,
                  value: f.id,
                  disabled: false,
                  selected: false
                }))
              : []
          }
        />
      </div>
      {fabricantId && installationId && (
        <div className="buttons-group">
          <PrimaryButton onClick={() => onContinue()}>
            {COPY.CONTINUE}
          </PrimaryButton>
        </div>
      )}
    </div>
  )
}

export { DeviceSelector }
