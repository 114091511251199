import React, { Fragment, ReactElement } from 'react'
import { COPY } from './common/copy'
import { Icon, PrimaryButton, ScreenTitle } from './ui/common'

const LoginScreen: React.FC = (): ReactElement => {
  return (
    <Fragment>
      <Icon
        className="background-image"
        src="/svgs/background.svg"
        alt="background"
      />
      <div className="init">
        <div className="central-box">
          <ScreenTitle>{COPY.LOGIN_TITLE}</ScreenTitle>
          <div className="controls">
            <PrimaryButton
              onClick={() => {
                window.location.href = '/api/auth/login?redirectTo=/'
              }}
            >
              {COPY.LOGIN_BUTTON_TEXT}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export { LoginScreen }
