import React from 'react'

import { buildSummary } from '../utils'
import { EditableSummary } from './EditableSummary'
import { COPY } from 'src/frontoffice/common/copy'
import useSessionState from '../store/useSessionState'

interface Summary {
  summary: string
  extraSummary?: string
}

/**
 * Renders a Final Summary
 * @returns {JSX.Element}
 */
const FinalScreenSummary: React.FC = () => {
  const { state: sessionState } = useSessionState()
  const { summary, extraSummary }: Summary = buildSummary(sessionState)

  return (
    <div className="final-screen-summary">
      <EditableSummary
        title={COPY.SUMMARY}
        summary={summary}
        showCopyButton={!extraSummary}
      />
      {extraSummary && (
        <EditableSummary
          title={COPY.EXTRA_SUMMARY}
          summary={extraSummary}
          showCopyButton={true}
        />
      )}
    </div>
  )
}

export { FinalScreenSummary }
