import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { createPortal } from 'react-dom'
import { DropdownOptionsList } from './DropdownOptionsList'
import { useClickOutside } from '../useClickOutside'
import { COPY } from 'src/frontoffice/common/copy'
import compareAlphaNumericStrings, { getBoundingClientRect } from 'src/lib/misc'
import { Icon } from '../index'

interface AutocompleteDropdownProps {
  onInputChange?: (v: string) => void
  onChange: (v?: string) => void
  placeholder?: string
  value?: string
  inputProps?: object
  autoFocus?: boolean
  options: {
    text: string
    value: string
    disabled?: boolean
    selected?: boolean
  }[]
}

function AutocompleteDropdown({
  placeholder,
  onChange,
  onInputChange,
  inputProps,
  value,
  options,
  autoFocus = false
}: AutocompleteDropdownProps) {
  const [opened, setOpened] = useState(false)
  const [menuPos, setMenuPos] = useState({ x: 0, y: 0, width: 0 })
  const inputRef = useRef<HTMLInputElement>(null)
  const dropRef = useRef<HTMLDivElement>(null)
  const [inputValue, setInputValue] = useState(value || '')
  const [focusedOptionIndex, setFocusedOptionIndexModifier] = useState(0)

  const getOptionById = (str: string) =>
    options.find(option => option.value === str)
  const selectedOption = getOptionById(inputValue)

  useEffect(() => {
    setInputValue(value || '')
  }, [value])

  const suggestions =
    inputValue && !selectedOption
      ? options.filter(option =>
          option.text.toLowerCase().includes(inputValue.toLowerCase())
        )
      : options

  const indexModifier = selectedOption
    ? suggestions.indexOf(selectedOption) === -1
      ? 0
      : suggestions.indexOf(selectedOption)
    : 0

  useLayoutEffect(() => {
    if (inputRef.current && opened) {
      const { x, y, width, height } = getBoundingClientRect(inputRef.current)
      setMenuPos({ x, y: y + height, width })
      setFocusedOptionIndexModifier(indexModifier)
    }
  }, [opened, indexModifier])

  useClickOutside(opened ? [inputRef, dropRef] : [], () => {
    setOpened(false)
    if (!getOptionById(inputValue)) {
      setInputValue('')
      onChange('')
    }
  })

  suggestions.sort((a, b) => compareAlphaNumericStrings(a.text, b.text))

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newInputValue = event.target.value
    setInputValue(newInputValue)
    onInputChange && onInputChange(newInputValue)
    setOpened(true)
  }

  const onDropdownListChange = (value: string) => {
    setOpened(false)
    onChange(value)
    setInputValue(value)
  }

  return (
    <div
      ref={inputRef}
      className="feenstra-autocomplete-dropdown"
      onClick={e => {
        if (e.target === inputRef.current) setOpened(true)
      }}
    >
      <input
        autoFocus={autoFocus}
        className="no-reset"
        type="text"
        value={selectedOption ? selectedOption.text : inputValue || ''}
        onChange={handleInputChange}
        placeholder={placeholder}
        {...inputProps}
        onClick={() => {
          setOpened(true)
        }}
        onKeyUp={e => {
          switch (e.key) {
            case 'Escape':
              if (opened) setOpened(false)
              break

            case 'ArrowDown':
              if (!opened) setOpened(true)
              setFocusedOptionIndexModifier(
                Math.max(
                  0,
                  Math.min(focusedOptionIndex + 1, suggestions.length - 1)
                )
              )
              break

            case 'ArrowUp':
              if (!opened) setOpened(true)
              setFocusedOptionIndexModifier(
                Math.max(
                  0,
                  Math.min(focusedOptionIndex - 1, suggestions.length - 1)
                )
              )
              break

            case 'Enter':
              if (opened)
                onDropdownListChange(suggestions[focusedOptionIndex].value)
              break
          }
        }}
      />
      {value && (
        <Icon
          onClick={() => onChange(undefined)}
          className="close"
          alt="Close"
          src="/svgs/close.svg"
        />
      )}
      {opened ? (
        <Icon
          onClick={() => setOpened(!opened)}
          className="arrow"
          alt="Arrow Up"
          src="/svgs/arrowup.svg"
        />
      ) : (
        <Icon
          onClick={() => setOpened(!opened)}
          className="arrow"
          alt="Arrow Down"
          src="/svgs/arrowdown.svg"
        />
      )}
      {opened &&
        createPortal(
          <div
            ref={dropRef}
            className="feenstra-autocomplete-dropdown-options-menu"
            style={{ left: menuPos.x, top: menuPos.y, minWidth: menuPos.width }}
          >
            <DropdownOptionsList
              options={
                suggestions.length
                  ? suggestions.map((option, index) =>
                      Object.assign({}, option, {
                        focused: index === focusedOptionIndex,
                        selected:
                          selectedOption &&
                          option.value === selectedOption.value
                      })
                    )
                  : [
                      {
                        text: COPY.NO_RESULTS,
                        value: '',
                        focused: false,
                        disabled: true,
                        selected: false
                      }
                    ]
              }
              onChange={onDropdownListChange}
            />
          </div>,
          document.body
        )}
    </div>
  )
}

export { AutocompleteDropdown }
