import React from 'react'
import { Component } from './frontoffice/Component'

import 'normalize.css'
import './styles.css'
import { UserProvider } from './frontoffice/contexts/UserContext'

function App() {
  return (
    <UserProvider>
      <Component runTrigger={() => {}} />
    </UserProvider>
  )
}

export default App
