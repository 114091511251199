import React, { createContext, useContext, useState, useEffect } from 'react'
import { getUser } from '../sandbox'
import { User } from '@/types/data'

type UserProviderProps = {
  children: React.ReactNode
}

type UserContextState =
  | { type: 'loading' }
  | { type: 'unauthenticated' }
  | { type: 'authenticated'; data: User }

const UserContext = createContext<UserContextState>({ type: 'loading' })

export const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = useState<UserContextState>({ type: 'loading' })

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userData = await getUser()
        if (userData === 'Unauthorized') {
          setUser({ type: 'unauthenticated' })
        } else {
          setUser({ type: 'authenticated', data: userData })
        }
      } catch (error) {
        console.error("Failed to fetch user's info", error)
      }
    }

    fetchUser()
  }, [])

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>
}

export const useUser = () => useContext(UserContext)
